import React from 'react';
import './../Profile.css';
import axios from "axios";

class ReservedVisits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfo: false,
			showEmptyInfo: true,
			visits: [],
			user: JSON.parse(localStorage.getItem('user')),
			userData: '',
			bookDAta: []
		};
		this.handlePoolReservation = this.handlePoolReservation.bind(this);
		this.handleVoid = this.handleVoid.bind(this);
	}
	
	componentDidMount() {
		this.findReservations(this.state.user)
	}
	
	handleVoid(reservationID) {
		// Cancel Visit
		axios.post(window.DEV_API + 'api/reservation/cancel', {
			reservation_id: reservationID
		}, {
			headers: {'Authorization': this.state.user.token}
		}).then(response => {
			if (!response.data.error) {
				this.findReservations(this.state.user)
			} else {
				alert("Error " + response.data.error)
			}
			
		}).catch(error => console.log(error))
		
		this.props.updateVisits(this.state.user);
	}
	
	findReservations() {
		if (this.state.user !== null) {
			
			let self = this;
			
			axios.post(window.DEV_API + 'api/user/profile', {}, {
				headers: {'Authorization': this.state.user.token}
			}).then(function (response) {
				localStorage.setItem('user', JSON.stringify(response.data.user));
				
				self.setState({
					userData: response.data.user,
				});
				
			}).catch(error => {
				console.log(error)
			})
		}
	};
	
	handlePoolReservation(type, id) {
		
		axios.get(window.DEV_API + "api/prices/")
			.then(response => {
				if (!response.data.error) {
					
					if(type === 'spa'){
						const data = response.data.prices.filter(item => item.type === type);
						localStorage.setItem('ticket-title', data[0].title);
						localStorage.setItem('product-id', data[0].product_id);
					} else {
						const data = response.data.prices.filter(item => item.product_id === parseInt(id) & item.type === type);
						localStorage.setItem('ticket-title', data[0].title);
						localStorage.setItem('product-id', id);
					}
					
					// Put all necessary data in localstorage
					localStorage.setItem('ticket-type', type);
					localStorage.setItem('selected-email', this.state.userData.email);
					
					// Redirect to choose count
					this.props.history.push('choose-count');
				} else {
					alert(response.data.error)
				}
			}).catch(error => console.log(error));
	}
	
	render() {
		const translate = this.props.translate;
		
		return (
			<div>
				
				<div className="profile__body">
					
					{/* Visits */}
					<h2>{translate.profile_body_title}</h2>
					
					{this.state.userData && (
						this.state.userData.subscriptions.map((subscription, index) =>
							<div key={index}>
								<span className="pool-size">{subscription.product_title}</span>
								
								<div className="profile__selected-times">
									
									{this.state.userData.reservations.filter(reservation => reservation.product_id === subscription.product_id).map((reservation, index) =>
										<div key={index} className="reserved-time">
											<div className="top">
												<p className='time'>{reservation.date}</p>
												<p>{reservation.product_title}</p>
											</div>
											{reservation.cancellable !== 0 ?
												<p className="bottom" onClick={e => this.handleVoid(reservation.id)}>
													<span> {translate.profile_body_cancel} </span>
												</p>
												: ''}
										</div>
									)}
									
									<div className="reserved-time add-new"
											 onClick={e => this.handlePoolReservation('single', subscription.product_id, subscription.product_title)}>
										<p className="add"> + </p>
										<p className="text">{translate.profile_body_add_new_visit}</p>
									</div>
								</div>
							</div>
						))}
					
					
					{/*	SPA */}
					{this.state.userData && (
						<div>
							<span className="pool-size">{translate.profile_body_spa}</span>
							
							<div className="profile__selected-times">
								
								{this.state.userData.tickets.map((ticket, index) =>
									<div key={index} className="reserved-time valid">
										<div className="top">
											<p className='time'>{ticket.title}</p>
										</div>
									</div>
								)}
								
								<div className="reserved-time add-new"
										 onClick={e => this.handlePoolReservation('spa', '', translate.profile_body_spa)}>
									<p className="add"> + </p>
									<p className="text">{translate.profile_body_add_new_visit}</p>
								</div>
							</div>
						</div>
					)}
					
				</div>
			</div>
		);
	}
}


export default ReservedVisits;
