import React from 'react';
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import Parallax from 'parallax-js';
import Select from 'react-select';
import axios from "axios";
import Countdown from 'react-countdown-now';

// Assets
import bgImg from "../../assets/img/book-visit.svg";
// Style
import './Book.css';

// Session Countdown
const renderer = ({minutes, seconds, completed}) => {
	if (completed) {
		return (<Redirect to='/'/>);
	} else {
		return <span>{minutes}:{seconds}</span>;
	}
};

let array = [];

class Summary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: props.isActive || true,
			selectedOption: null,
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedBookType: localStorage.getItem('ticket-type'),
			selectedPoolType: localStorage.getItem('pool-title'),
			selectedPoolID: localStorage.getItem('product-id'),
			selectedCount: localStorage.getItem('selected-count'),
			selectedCountTitle: localStorage.getItem('selected-count-title'),
			selectedDate: localStorage.getItem('selected-date'),
			selectedTime: localStorage.getItem('selected-time'),
			selectedEmail: localStorage.getItem('selected-email'),
			selectedCode: localStorage.getItem('personal-code'),
			loggedIn: localStorage.getItem('loggedIn'),
			timer: localStorage.getItem('timer'),
			sessionID: localStorage.getItem('sessionID'),
			reservedTickets: JSON.parse(localStorage.getItem('reserved-tickets')),
			ticketPrice: localStorage.getItem('price'),
			ticketDiscountPrice: localStorage.getItem('discount-price'),
			user: JSON.parse(localStorage.getItem('user')) || false,
			userEmail: localStorage.getItem('selected-email'),
			userPersonalCode: localStorage.getItem('personal-code'),
			payment: '',
			userToken: '',
			availableSubscriptions: 0,
			showPrice: true,
			showPriceAndSubscription: false,
			showSubscription: false,
			payRest: '',
			showTicketCount: true,
			showCheckbox: false
		};

		this.handleCheck = this.handleCheck.bind(this);
		this.submitOrder = this.submitOrder.bind(this);
		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {

		// Redirect to home page if selected data are empty
		if (!this.state.selectedBookTypeTitle || !this.state.selectedPoolType || !this.state.selectedCount) {
			this.props.history.push('/' + this.props.language + '/book-visit');
		}

		// Activate parallax
		this.parallax = new Parallax(this.scene);

		// If Admin is logged In
		if (this.state.user.group === '1') {
			this.setState({
				showPrice: false,
				userToken: this.state.user.token
			});
		}

		// Add registered user token / email  to order authorization
		if (this.state.user.group === '0') {
			this.setState({
				selectedEmail: this.state.user.email,
				userToken: this.state.user.token,
			})
		}

		// Disable discount select if selected small pool
		if (this.state.selectedPoolID === '2') {
			this.setState({
				showTicketCount: false
			})
		}

		if (this.state.selectedBookType === 'single') {
			if (this.state.user) {
				// Check user subscriptions
				const subscriptionCount = this.state.user.subscriptions.find((item) => item.product_id === this.state.selectedPoolID);
				const result = subscriptionCount.available - this.state.selectedCount;

				// If logged in user - by default disable price button and check if subscriptions are available
				this.setState({
					showPrice: false,
				});

				this.setState({
					availableSubscriptions: subscriptionCount.available
				});

				// Show price button
				if (subscriptionCount.available === 0) {
					this.setState({
						showPrice: true
					})
				}

				// Show subscription button
				if (result >= 0) {
					this.setState({
						showSubscription: true,
						showTicketCount: false
					})
				}

				if (result < 0) {
					this.setState({
						showPrice: true
					})
				}

				// Show pay and subscription
				if (result < 0 && subscriptionCount.available > 0) {
					this.setState({
						showPriceAndSubscription: true,
						payRest: Math.abs(result * this.state.ticketPrice)
					})
				}
			}
		}

		// Set default price without discount
		if (this.state.selectedBookType !== 'subscription') {
			this.setState({
				price: this.state.ticketPrice * this.state.selectedCount
			})
		} else {
			this.setState({
				price: this.state.ticketPrice
			})
		}

		// Disable discount select if selected small pool
		if (this.state.selectedBookType === 'family') {
			this.setState({
				showTicketCount: false
			})

			axios.post(window.DEV_API + 'api/ticket', {
				session_id: this.state.sessionID,
				discount: this.state.selectedCount > 3 ? 1 : 0,
			})
			
			if (this.state.selectedCount > 3) {
				this.setState({
					price: this.state.ticketDiscountPrice * this.state.selectedCount
				})
			}
		}

		// Show only one ticket if ticket type are subscription or family
		if (this.state.selectedBookType !== 'single') {
			this.setState({
				reservedTickets: [{}]
			})

			if (this.state.user.group === '1') {
				this.setState({
					showPrice: true
				})
			}
		}
		// window.addEventListener('popstate', this.handleLink);
	}

	componentWillUnmount() {
		// disable parallax on component leave
		this.parallax.disable()
	}

	submitOrder(chargeMethod) {
		let self = this;

		this.setState({
			isActive: false
		})
		
		axios.post(window.DEV_API + 'api/order', {
			session_id: this.state.sessionID,
			email: this.state.selectedEmail,
			code: this.state.selectedCode,
			payment: chargeMethod
		}, {
			headers: {'Authorization': this.state.userToken}
		}).then(function (response) {
			
			
			if (response.data.error === 'session') {
				alert('sesija ir beigusies')
			} else {
				// Proceed to checkout
				if (response.data.session_id) {
					window.stripe.redirectToCheckout({
						// Make the id field from the Checkout Session creation API response
						// available to this file, so you can provide it as parameter here
						// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
						sessionId: response.data.session_id
					}).then(function (result) {
						// If `redirectToCheckout` fails due to a browser or network
						// error, display the localized error message to your customer
						// using `result.error.message`.
					});

					// Payment made by admin or paid with subscription, no checkout
				} else {

					if (response.data.files) {
						localStorage.setItem('files', JSON.stringify(response.data.files))
					}

					setTimeout(
						function () {
							if (!response.data.download) {
								self.props.history.push('/' + self.props.language + '/confirmation?status=success');
							} else {
								self.props.history.push('/' + self.props.language + '/confirmation?status=success&download=' + response.data.download + '');

							}
						}
							.bind(this),
						300
					);
				}
			}

		}).catch(error => {
			console.log(error)
		})
	}

	handleCheck() {
		this.setState({
			isActive: !this.state.isActive
		});
	}

	handleChange = (selectedOption) => {
		this.setState({
			selectedOption,
		});

		if (this.state.user.group !== '1') {

			if (selectedOption.discount === 1) {
				array.indexOf(selectedOption.id) === -1 ? array.push(selectedOption.id) : console.log("This item already exists");
				
				if (array.length > 0) {
					this.setState({
						showCheckbox: true,
						isActive: false
					})
				}
			} else {
				array = array.filter(item => item !== selectedOption.id)

				if (array.length === 0) {
					this.setState({
						showCheckbox: false,
						isActive: true
					})
				}
			}
		}

		let self = this;

		axios.post(window.DEV_API + 'api/ticket', {
			session_id: this.state.sessionID,
			ticket_id: selectedOption.id,
			discount: selectedOption.discount,
		}).then(function (response) {
			if (response.data.error === 'session') {
				alert('sesija ir beigusies')
			} else {
				self.setState({
					price: response.data.total,
				})
			}

		}).catch(error => {
			console.log(error)
		})
	};

	handleOptions = (options, id) => {
		return [
			{
				value: this.state.ticketPrice,
				label: this.props.translate.summary_option_title_1,
				discount: 0,
				id: id
			},
			{
				value: this.state.ticketDiscountPrice,
				label: this.props.translate.summary_option_title_2,
				discount: 1,
				id: id
			},
		]
	};

	handleTicketCount() {

		let tickets = []

		this.state.reservedTickets.map((ticket, index) =>

			tickets.push(
				<div key={index} className="item">
					<div>
						<p>{this.state.selectedBookType === 'subscription'
							? this.props.translate.summary_subscription
							: this.props.translate.summary_ticket + (' ' + (index + 1))} :
						</p>
					</div>

					<div className="select-wrap">
						<Select
							className="react-select"
							classNamePrefix="react-select"
							isSearchable={false}
							placeholder={this.props.translate.summary_option_title_1}
							onChange={this.handleChange}
							options={this.handleOptions(this, ticket.id)}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: '#175aa8',
									primary: '#175aa8',
								},
							})}
						/>

					</div>
				</div>
			)
		)

		return tickets
	}

	handleLink() {
		if (this.state.user.group === '0' && (this.state.selectedBookType === 'subscription' || this.state.selectedBookType === 'spa')) {
			this.props.history.push('/' + this.props.language + '/choose-count');

		} else if (this.state.user.group === '0' && (this.state.selectedBookType === 'single' || this.state.selectedBookType === 'family')) {
			this.props.history.push('/' + this.props.language + '/choose-date');

		} else if ((this.state.userEmail || this.state.userPersonalCode) && this.state.user.group === '0') {
			this.props.history.push('/' + this.props.language + '/choose-date')
		} else {
			this.props.history.push('/' + this.props.language + '/choose-checkout')
		}
		// window.removeEventListener('popstate', this.handleLink);
	}

	render() {

		const translate = this.props.translate;

		return (
			<div>
				<Helmet>
					<title>{translate.summary_title}</title>
				</Helmet>

				<div className="container-fluid summary">
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>

					<p className="back-link"
						 onClick={this.handleLink}>
					</p>

					<div className="session">
						<p>{translate.summary_session}</p>
						<p className="session-time">
							<Countdown
								date={parseInt(this.state.timer) + 900000}
								renderer={renderer}/>
						</p>
					</div>

					<h1>{translate.summary_title}</h1>

					<div className="summary__content">

						{/* Title */}
						<div className="item featured">
							<p>{this.state.selectedBookTypeTitle}</p>
						</div>

						{/* E-mail */}
						{((this.state.selectedEmail || this.state.selectedBookType === 'family') &&
							<div className="item">
								<div><p>{translate.summary_email}</p></div>
								<div><p>{this.state.selectedEmail}</p></div>
							</div>
						)}

						{/* Personal code */}
						{this.state.selectedCode && (
							<div className="item">
								<div><p>{translate.form_personal_code}</p></div>
								<div><p>{this.state.selectedCode}</p></div>
							</div>
						)}

						{/* Pool Title */}
						{this.state.selectedBookType !== 'family' && (
							<div className="item">
								<p>{this.state.selectedPoolType}</p>
							</div>
						)}

						{/* Selected date */}
						{((this.state.selectedBookType === 'single' || this.state.selectedBookType === 'family') &&
							<div className="item">
								<p>{this.state.selectedDate}, {this.state.selectedTime}</p>
							</div>
						)}

						{/* Ticket Count */}
						{this.state.showTicketCount && (
							this.handleTicketCount()
						)}

						{/* Family select Title*/}
						{this.state.selectedBookType === 'family' && (
							<div className="item">
								<div><p>{translate.summary_family_count}</p></div>
								<div><p>{this.state.selectedCountTitle}</p></div>
							</div>
						)}

						{/* Price */}
						{this.state.showPrice && (
							<div className="item">
								<div><p>{translate.summary_price}</p></div>
								<div><b>{this.state.price} {translate.summary_euro}</b></div>
							</div>
						)}

						{/* Subscriptions */}
						{this.state.showSubscription && (
							<div className="item">
								<div>
									<p>{translate.summary_pay_with_subscription}
										<span> ({translate.summary_subscriptions_left}: {this.state.availableSubscriptions})</span>
									</p>
								</div>
								<div><b>{this.state.selectedCount}</b></div>
							</div>
						)}

						{/* Price and Subscriptions */}
						{this.state.showPriceAndSubscription && (
							<div className="item-price-subscription">
								<div className="item">
									<p>{translate.summary_pay_with_subscription}
										<span> ({translate.summary_subscriptions_left}: {this.state.availableSubscriptions})</span>
									</p>
									<div><b>{this.state.selectedCount}</b></div>
								</div>

								<div className="item">
									<div><p>{translate.summary_and_pay}</p></div>
									<div><b>{this.state.payRest} {translate.summary_euro}</b></div>
								</div>
							</div>

						)}

						{/* Rules checkbox */}
						{(this.state.user.group !== '1' && this.state.showCheckbox) && (
							<div className='accept-rules'>
								<label><input type="checkbox" onChange={this.handleCheck} checked={this.state.isActive}/>
									<div></div>
									<p>{translate.summary_checkbox_label}</p>
								</label>
							</div>
						)}

						{/* Pay buttons   */}
						{/* ------------------------------------------------------------------- */}
						{/* Par with card */}
						{this.state.showPrice && (
							<button
								disabled={!this.state.isActive}
								onClick={e => this.submitOrder('charge')}
								className="pay-button">

								{translate.summary_pay + ' ( ' + this.state.price + ' ' + translate.summary_euro + ' )'}
							</button>
						)}

						{/*Pay with subscriptions */}
						{this.state.showSubscription && (
							<button
								disabled={!this.state.isActive}
								onClick={e => this.submitOrder('subscriptions')}
								className="pay-button">

								{translate.summary_use_subscription + ' '}
								(-{this.state.selectedCount} {this.state.selectedCount > 1 ? translate.summary_more_times : translate.summary_one_time})
							</button>
						)}


						{/*Pay with subscription and card */}
						{this.state.showPriceAndSubscription && (
							<button
								disabled={!this.state.isActive}
								onClick={e => this.submitOrder('surcharge')}
								className="pay-button">

								{translate.summary_use_subscription + ' '}
								(-{this.state.availableSubscriptions}{translate.summary_use_time})
								{' ' + translate.summary_and_pay} ({this.state.payRest} {translate.summary_euro})
							</button>
						)}

					</div>
				</div>
			</div>
		);
	}
}

export default Summary;
