import React from "react";
import axios from "axios";
import moment from "moment";
import './Calendar.css'

import 'moment/locale/lv'
import 'moment/locale/en-gb'
import 'moment/locale/ru'

//If Lang  = LV
moment.locale('lv');
// If Lang = EN
//moment.locale('en-gb');
// If Lang = RU
//moment.locale('ru');

const defaultFormat = "dddd Do MMMM";

export default class Calendar extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			dateObject: moment(),
			prevMonth: moment().subtract(1, 'month'),
			weekdays: window.innerWidth > 991
				? moment.weekdays(true)
				: moment.weekdaysShort(true),
			allmonths: moment.months(),
			width: window.innerWidth,
			isDesktop: false,
			show: props.hideCalendar,
			newLink: props.navLink,
			monthInterval: 1,
			weekInterval: 0,
			data: [],
			prices: [],
			available: [],
			calendarArray: [],
			daysinmonth: [],
			fields: [],
			selectedBookType: localStorage.getItem('ticket-type'),
			selectedPoolID: localStorage.getItem('product-id'),
			selectedPriceID: localStorage.getItem('priceId'),
			selectedCount: localStorage.getItem('selected-count'),
			sessionID: localStorage.getItem('sessionID'),
			user: JSON.parse(localStorage.getItem('user'))
		};
		this.updateDimensions = this.updateDimensions.bind(this);
	}
	
	componentDidMount() {
		
		// Get calendar data
		axios.post(window.DEV_API + "api/usage/", {
			product_id: this.state.selectedPoolID,
			date_from: this.state.dateObject.format('YYYY-MM-DD')
		}).then(result => {
			this.setState({data: result.data.usage});
			this.fillCalendarData();
		}).catch(error => this.setState({error}));
		
		// Get available ticked count on selected day
		axios.post(window.DEV_API + "api/available/", {
			product_id: this.state.selectedPoolID,
			date_time: this.state.dateObject.format('YYYY-MM-DD 8:00:00')
		}).then(result => {
			this.setState({available: result.data.available});
		}).catch(error => this.setState({error}));
		
		// Save price id / discount prices not selected
		if (this.state.selectedPoolID === '2' || this.props.actionFromAdmin === true) {
			
			axios.get(window.DEV_API + "api/prices/").then(result => {
				const price = result.data.prices.find((item) =>
					item.type === this.state.selectedBookType &
					item.product_id === parseInt(this.state.selectedPoolID));
				
				localStorage.setItem('price', price.price);
				localStorage.setItem('discount-price', price.discount);
				localStorage.setItem('priceId', price.id);
			}).catch(error => console.log(error));
		}
		
		this.updateDimensions();
		
		window.addEventListener("resize", this.updateDimensions);
	}
	
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}
	
	componentWillReceiveProps(nextProps) {
		this.setState({show: nextProps.hideCalendar})
	}
	
	updateDimensions() {
		this.setState({
			weekdays: window.innerWidth > 991
				? moment.weekdays(true)
				: moment.weekdaysShort(true)
		});
	}
	
	firstDayOfMonth = () => {
		return this.state.dateObject.startOf("month").format("d");
	};
	
	onPrev = () => {
		if (this.state.monthInterval !== 1) {
			this.setState({
				prevMonth: this.state.prevMonth.subtract(1, "month"),
				dateObject: this.state.dateObject.subtract(1, "month"),
				monthInterval: this.state.monthInterval + 1
			});
			this.fillCalendarData();
		}
	}
	
	onNext = () => {
		if (this.state.monthInterval !== 0) {
			this.setState({
				prevMonth: this.state.prevMonth.add(1, "month"),
				dateObject: this.state.dateObject.add(1, "month"),
				monthInterval: this.state.monthInterval - 1
			});
			this.fillCalendarData();
		}
	}
	
	onDayClick = (d) => {
		this.props.changeDate(this.state.dateObject.date(d).format(defaultFormat));
		this.props.changeLink(!this.state.newLink);
		this.timeFieldMarkup(d)
	};
	
	fillEventHours = (dateToCheck) => {
		
		let busyHours = [],
			dateFound = false;
		
		if (this.state.data.length > 0) {
			
			const item = this.state.data.find((item) => item.date === dateToCheck & item.usage > 0);
			if (item) {
				if (item.disabled === 0) {
					let size = Object.keys(item.hours).length;
					let hoursArray = Object.values(item.hours);
					
					for (let i = 0; i < size; i++) {
						busyHours.push(
							<span key={i} className="default-bar">
								<i className={`${hoursArray[i].usage > 80 ? 'very-busy' : 'busy'}`}
									 style={{height: hoursArray[i].usage + '%'}}>{""}</i>
							</span>)
						}
					
					dateFound = true
				}
			}
		}
		
		if (!dateFound) {
			for (let i = 0; i < 16; i++) {
				busyHours.push(
					<span key={i} className="default-bar">
          	<i>{""}</i>
        	</span>)
			}
		}
		
		return busyHours
	}
	
	fillCalendarData = () => {
		let currentMonth = moment().format("M");
		let thisday = moment().format("D");
		let daysBefore = '',
			emptyDays = '',
			currentDay = '',
			blanks = [],
			lastBlanks = [],
			daysinmonth = [],
			rows = [],
			cells = [],
			totalSlots = [];
		
		let firstDayOfMonth = this.firstDayOfMonth() == 0 ? 7 : this.firstDayOfMonth();
		
		for (let i = 0; i < firstDayOfMonth - 1; i++) {
			let num = firstDayOfMonth - 1;
			
			blanks.push(<td key={'first-days-' + i} className="calendar-day other-month">
				<span>{((this.state.prevMonth.daysInMonth() - num) + 1) + i}.</span>
			</td>);
		}
		
		for (let d = 1; d <= this.state.dateObject.daysInMonth(); d++) {
			
			// Functions with current month
			if (currentMonth === this.state.dateObject.format("M")) {
				
				// Find today date
				if (thisday === d.toString()) {
					currentDay = "today "
				} else {
					currentDay = ""
				}
				
				// Find days before current day
				if (thisday > d) {
					daysBefore = "disabled-days ";
				} else {
					daysBefore = "";
				}
			}
			
			// Find day without working time
			const item = this.state.data.find(item => item.date === this.state.dateObject.date(d).format("YYYY-MM-DD"))
			
			if (item) {
				if (item.disabled === 1) {
					emptyDays = "empty-days ";
				} else {
					emptyDays = "";
				}
			}
			
			daysinmonth.push(
				<td key={d} onClick={this.onDayClick.bind(this, d)}
						className={'calendar-day ' + currentDay + daysBefore + emptyDays}>
					
					<span>{d}.</span>
					
					<div className="busy-hours">
						{this.fillEventHours(this.state.dateObject.date(d).format("YYYY-MM-DD"))}
					</div>
				</td>);
		}
		
		let number = (parseInt(firstDayOfMonth - 1) + parseInt(this.state.dateObject.daysInMonth()));
		let restBlanks = number % 7;
		let restBlanksCalc = 0;
		
		if (restBlanks !== 0) {
			restBlanksCalc = (7 - restBlanks);
		} else {
			restBlanksCalc = (7 - restBlanks);
		}
		
		for (let i = 0; i < restBlanksCalc; i++) {
			lastBlanks.push(<td key={'first-days-' + i} className="calendar-day other-month">
				<span>{moment().date(i + 1).format("D")}.</span>
			</td>);
		}
		
		totalSlots = [
			...blanks,
			...daysinmonth,
			...lastBlanks
		];
		
		totalSlots.forEach((row, i) => {
			if (i % 7 !== 0) {
				cells.push(row);
			} else {
				rows.push(cells);
				cells = [];
				cells.push(row);
			}
			if (i === totalSlots.length - 1) {
				rows.push(cells);
			}
		});
		
		daysinmonth = rows.map((d, i) => {
			return <tr key={'daysinmonth' + i}>{d}</tr>;
		});
		
		this.setState({daysinmonth: daysinmonth});
	}
	
	// *************************
	// Select time
	// *************************
	
	timeFieldMarkup = (selectedDate) => {
		
		this.setState({fields: []});
		
		let data = [];
		let status = '';
		let availableTickets= '';
		let className = 'not-busy';
		let wrapClassName = 'item col-md-3 col-sm-6 col-xs-12';
		let selectedDateFormated = this.state.dateObject.date(selectedDate).format("YYYY-MM-DD")
		let item = this.state.data.find((item) => item.date === selectedDateFormated);
		
		
		if (item) {
			if (item.disabled === 0) {
				let time = Object.keys(item.hours);
				let size = time.length;
				let hoursArray = Object.values(item.hours);
				
				for (let i = 0; i < size; i++) {
					className = '';
					
					// Add colored circles next to time
					if (hoursArray[i].usage > 40) {
						className = 'busy';
					}
					
					if (hoursArray[i].usage > 80) {
						className = 'very-busy';
					}
					
					// Add elements to free or reserved items
					if (hoursArray[i].status === 'free') {
						wrapClassName = 'free';
						status = this.props.translate.calendar_free;
					}
					
					if (hoursArray[i].status === 'partly') {
						wrapClassName = 'partly';
						status = this.props.translate.calendar_partly;
					}
					
					if (hoursArray[i].status === 'reserved') {
						wrapClassName = 'closed';
						status = this.props.translate.calendar_reserved;
					}
					if (hoursArray[i].available === 0 && hoursArray[i].status !== 'reserved') {
						wrapClassName = 'not-available';
						status = this.props.translate.calendar_closed;
					}
					
					data.push({
						id: i,
						time: time[i],
						availableTickets: hoursArray[i].available,
						wrapClassName: wrapClassName,
						className: className,
						status: status,
						title: hoursArray[i].title
					});
				}
			}
			
			if (item.disabled === 1) {
				data.push({
					id: 0,
					time: 0,
					availableTickets: '0',
					wrapClassName: "placeholder",
					className: "placeholder-span",
					status: this.props.translate.reservation_not_available,
					title: this.props.translate.reservation_not_available_subtitle
				});
			}
		}
		
		this.setState({fields: data});
	}
	
	getDateAndTime = (event) => {
		
		// Save selected time to localstorage
		localStorage.setItem('selected-time', event.currentTarget.getAttribute('data-time'));
		
		// Start session timer
		localStorage.setItem('timer', Date.now())
		
		if (localStorage.getItem('priceId')) {
			if (this.props.actionFromAdmin !== true) {
				// Reserve selected tickets for 15 min
				let self = this;
				
				axios.post(window.DEV_API + 'api/reservation/start', {
					session_id: this.state.sessionID,
					product_id: this.state.selectedPoolID,
					price_id: localStorage.getItem('priceId'),
					date_time: this.state.dateObject.format("YYYY-MM-DD " + event.currentTarget.getAttribute('data-time')),
					count: this.state.selectedCount
				}).then(function (response) {
					// Save received tickets to localstorage
					localStorage.setItem('reserved-tickets', JSON.stringify(response.data.tickets))
					
					// Save session ID if session doesn't exist
					if (!self.state.sessionID) {
						localStorage.setItem('sessionID', response.data.session)
					}
					
					// Check if session is still active or tickets are still available
					if (response.data.error) {
						
						if (response.data.error === 'session') {
							alert("Sesija ir beigusies")
						}
						if (response.data.error === 'not_available') {
							alert("biļetes vairs nav pieejamas")
						}
						
						//	If all good continue to next step
					} else {
						if (localStorage.getItem('loggedIn') === 'admin') {
							self.props.parentProps.history.push('choose-checkout');
						} else {
							self.props.parentProps.history.push('rules')
						}
					}
				}).catch(error => {console.log(error)})
			} else {
				
				axios.post(window.DEV_API + 'api/admin/reservation', {
					subscription_id: this.props.code,
					price_id: localStorage.getItem('priceId'),
					product_id: localStorage.getItem('product-id'),
					date_time: this.state.dateObject.format("YYYY-MM-DD " + event.currentTarget.getAttribute('data-time') + ':00')
				}, {
					headers: {'Authorization': this.state.user.token}
				}).then(response => {
					if (!response.data.error) {
						this.props.returnToAdmin()
					} else {
						alert("error " + response.data.error)
					}
				}).catch(error => console.log(error))
				
			}
		} else {
			alert('Something went wrong')
		}
	};
	
	
	onPrevDay = () => {
		if (this.state.weekInterval !== -30) {
			this.setState({
				weekInterval: this.state.weekInterval - 1
			});
			this.timeFieldMarkup(this.state.dateObject.subtract(1, "day").format('DD'))
		}
		localStorage.setItem('selected-date', this.state.dateObject.format(defaultFormat));
		this.props.changeDate(this.state.dateObject.format(defaultFormat));
	}
	
	onNextDay = () => {
		if (this.state.weekInterval !== 30) {
			this.setState({
				weekInterval: this.state.weekInterval + 1
			});
			
			this.timeFieldMarkup(this.state.dateObject.add(1, "day").format('DD'))
		}
		localStorage.setItem('selected-date', this.state.dateObject.format(defaultFormat));
		this.props.changeDate(this.state.dateObject.format(defaultFormat));
	}
	
	render() {
		const translate = this.props.translate;
		
		return (<div className="calendar-wrap">
			
			<div className={this.state.show
				? "tail-datetime-calendar"
				: "hidden"}>
				<div className="calendar-navi">
					
					<div>
						<p>{translate.calendar_choose_day}</p>
					</div>
					
					<div className="calendar-switch">
						<span>{this.state.dateObject.format("MMMM")}</span>
						<span>{this.state.dateObject.format("Y")}</span>
						
						<span onClick={this.onPrev} className="calendar-button button-prev"/>
						<span onClick={this.onNext} className="calendar-button button-next"/>
					</div>
				
				</div>
				
				<div className="calendar-date">
					<table className="calendar-day">
						<thead>
						<tr>
							{this.state.weekdays.map((day) => <th key={day}>{day}</th>)}
						</tr>
						</thead>
						<tbody>{this.state.daysinmonth}</tbody>
					</table>
				</div>
			</div>
			
			<div className={this.state.show
				? "hidden"
				: "select-time"}>
				<div className="calendar-navi">
					<div>
						<p className="choose-time">{translate.calendar_choose_time}</p>
					</div>
					
					<div className="calendar-switch">
						<span>{this.state.dateObject.format(defaultFormat)}</span>
						
						<span onClick={e => {
							this.onPrevDay()
						}} className="calendar-button button-prev"/>
						<span onClick={e => {
							this.onNextDay()
						}} className="calendar-button button-next"/>
					</div>
				</div>
				
				<div className="container">
					<div className="row">
						{
							this.state.fields.map((item) =>
								<div key={item.id}
										 data-time={item.time}
										 onClick={this.getDateAndTime} className={`item col-md-3 col-sm-6 col-xs-12 ${item.wrapClassName}`}>
									<span className={`${item.className}`}>{item.time}</span>
									<p className="available-count">({item.availableTickets})</p>
									
									<div className="reservation-info">
										<p className="item-status">{item.status}</p>
										<span>{item.title}</span>
									</div>
								</div>)
						}
					</div>
					
					<div className="row">
						<div className="usage-description">
							<div className="not-busy">
								<p>{translate.calendar_load_low}</p>
							</div>
							
							<div className="busy">
								<p>{translate.calendar_load_medium}</p>
							</div>
							
							<div className="very-busy">
								<p>{translate.calendar_load_high}</p>
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="brackets-description">
							<p>{translate.calendar_bottom_brackets_description}</p>
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}
