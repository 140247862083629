import React from 'react';
import axios from 'axios';
import moment from "moment";
import {NavLink} from "react-router-dom";
import {Helmet} from 'react-helmet';

import NumberImage from '../../assets/img/01.svg';

import './Home.css';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			meta: [],
			data: [],
			clients: [],
			hours: [],
			hoursText: '',
			hoursCssClass: '',
			in_1_hour: '',
			in_2_hour: '',
			in_3_hour: '',
			showText: false
		};
	}
	
	componentDidMount() {
		
		// Clear localstorage, to get right configuration for ticket reservation
		localStorage.removeItem('timer');
		localStorage.removeItem('price');
		localStorage.removeItem('priceId');
		localStorage.removeItem('discount-price');
		localStorage.removeItem('ticket-title');
		localStorage.removeItem('ticket-type');
		localStorage.removeItem('pool-title');
		localStorage.removeItem('product-id');
		localStorage.removeItem('selected-count');
		localStorage.removeItem('selected-count-title');
		localStorage.removeItem('selected-date');
		localStorage.removeItem('selected-time');
		localStorage.removeItem('selected-email');
		localStorage.removeItem('personal-code');
		localStorage.removeItem('sessionID');
		localStorage.removeItem('reserved-tickets');
		localStorage.removeItem('files');
		
		//  get content
		axios.get(window.API + this.props.match.url)
			.then(response => {
				if (!response.data.error) {
					this.setState({
						meta: response.data.meta,
						data: response.data.main,
					})
				} else {
					alert(response.data.error)
				}
			}).catch(error => console.log(error));
		
		// get pool data
		axios.get(window.DEV_API + "api/today")
			.then(response => {
				let in_1_hour = Math.round((response.data.clients.in_1_hour * 100) / response.data.capacity);
				let in_2_hour = Math.round((response.data.clients.in_2_hours * 100) / response.data.capacity)
				let in_3_hour = Math.round((response.data.clients.in_3_hours * 100) / response.data.capacity)
				
				if (!response.data.error) {
					this.setState({
						clients: response.data.clients,
						hours: response.data.hours,
						in_1_hour: in_1_hour,
						in_2_hour: in_2_hour,
						in_3_hour: in_3_hour,
					})
				} else {
					alert(response.data.error)
				}
				
				this.handleOpeningHours();
			}).catch(error => console.log(error));
		
	}
	
	handleOpeningHours = () => {
		
		let startTime = moment(this.state.hours.start, "HH:mm");
		let endTime = moment(this.state.hours.end, "HH:mm");
		let currentTime = moment(moment().format("HH:mm"), "HH:mm");
		
		let duration = moment.duration(endTime.diff(startTime));
		let workedTime = moment.duration(endTime.diff(currentTime));
		
		let workingHours = parseInt(duration.asHours());
		let timeToWork = parseInt(workedTime.asHours());
		
		let hours = workingHours - timeToWork;
		
		setInterval(() => {
			// if (hours === 1) {
			// 	this.setState({
			// 		hoursText: this.props.translate.opens_soon,
			// 		hoursCssClass: 'working__soon'
			// 	})
			// } else
			if (timeToWork > 0) {
				this.setState({
					hoursText: this.props.translate.open,
					hoursCssClass: '',
					showText: true
				})
			} else {
				this.setState({
					hoursText: this.props.translate.closed,
					hoursCssClass: 'working__closed',
					showText: false
				})
			}
		}, 100);
	};
	
	render() {
		
		const translate = this.props.translate;
		
		return (
			<div className="home">
				
				<Helmet>
					<title>{this.state.meta.title}</title>
					<meta name="description" content={this.state.meta.description}/>
					<meta name="keywords" content={this.state.meta.keywords}/>
				</Helmet>
				
				<div className="home__grid">
					<div className="home__grid--col">
						<div className="number">
							<img src={NumberImage} alt=""></img>
						</div>
					</div>
					<div className="home__grid--col"></div>
					<div className="home__grid--col"></div>
					<div className="home__grid--col"></div>
				</div>
				
				<div className="home__text">
					<h1>{this.state.data.title}</h1>
					<p dangerouslySetInnerHTML={{__html: this.state.data.text}}></p>
					<p>{translate.home_intro}</p>
					
					<div className="home__btn--wrap">
						<NavLink to='/lv/book-visit' className="btn">
							{translate.home_reserve_visit}
						</NavLink>
						
						
						<div className="working">
							{/* Remove working time */}
							{this.state.showText && (
								<h6>{translate.home_work_time} - {this.state.hours.end}</h6>
							)}
							<span className={this.state.hoursCssClass}>
								{this.state.hoursText}
							</span>
						</div>
					</div>
				</div>
				
				<div className="home__users">
					
					<div className="titles">
						
						<div>
							<p>{translate.home_now} <br/>{translate.home_in_pool}</p>
						</div>
						
						<div>
							<p>{translate.predictable} <br/>{translate.home_swimmer_count}</p>
						</div>
					
					</div>
					
					<div className="currently">
						<div>
							<h3>{this.state.clients.now}</h3>
							<p>{translate.home_swimmers}</p>
						</div>
					</div>
					
					<div className="reserved">
						<ul>
							<li>
								<p>{translate.home_after} 1 {translate.home_hour} {this.state.clients.in_1_hour} {translate.home_swimmers}</p>
								<div className="fill" style={{width: this.state.in_1_hour + '%'}}></div>
							</li>
							<li>
								<p>{translate.home_after} 2 {translate.home_hours} {this.state.clients.in_2_hours} {translate.home_swimmers}</p>
								<div className="fill" style={{width: this.state.in_2_hour + '%'}}></div>
							</li>
							<li>
								<p>{translate.home_after} 3 {translate.home_hours} {this.state.clients.in_3_hours} {translate.home_swimmers}</p>
								<div className="fill" style={{width: this.state.in_3_hour + '%'}}></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
