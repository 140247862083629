import React from 'react';
import {Helmet} from "react-helmet";
import Parallax from 'parallax-js';

// Style
import bgImg from "../../assets/img/book-visit.svg";

class Confirmation extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
		
		this.goToLogin = this.goToLogin.bind(this);
		this.goToHome= this.goToHome.bind(this)
	}
	
	componentDidMount() {
		// Activate parallax
		this.parallax = new Parallax(this.scene);
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	goToLogin(){
		this.props.history.push('login')
	}
	
	goToHome(){
		this.props.history.push('')
	}
	
	render() {
		const translate = this.props.translate;
		
		return (
			<div>
				<Helmet>
					<title>{translate.password_confirmation_title}</title>
				</Helmet>
				
				<div className="container-fluid summary">
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					
					<div className="confirmation__content">
						<h1>{translate.password_confirmation_title}</h1>
						
						<p className="description">{translate.password_confirmation_subtitle}</p>
						
						<p className="btn-red btn" onClick={this.goToLogin}>{translate.password_confirmation_login}</p>
						
						<p className="home-link" onClick={this.goToHome}>{translate.confirmation_back_to_home}</p>
					
					</div>
				
				</div>
			</div>
		);
	}
}

export default Confirmation;
