import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Map from './../partials/Map';
import Buy from '../partials/Buy';
import './Contacts.css';

class Contacts extends React.Component {
  constructor(){
    super();
    this.state = {
      meta: [],
      data: []
    };
  }

  componentDidMount() {
    axios.get(window.API + this.props.match.url)
    .then(result => {
        this.setState({
          meta: result.data.meta,
          data: result.data.contacts
        })
      })
      .catch(error => this.setState({
        error
      }));
  }

  render () {

    return (
      <div>
        <Helmet>
          <title>{this.state.meta.title}</title>
          <meta name="description" content={this.state.meta.description} />
          <meta name="keywords" content={this.state.meta.keywords} />
        </Helmet>

        <div className="contacts container">
          <h1>{this.state.meta.title}</h1>

          <div className="row row-eq-height">
            <div className="col col--map col-sm-12 col-md-12 col-lg-8 d-none d-lg-block">
              <div className="contacts__map">
                <Map lat={this.state.data.latitude} lng={this.state.data.longitude} />
              </div>
            </div>

            <div className="col col-12 col--details col-sm-12 col-md-12 col-lg-4">
              <div className="contacts__details">
                <h2>{this.state.data.text}</h2>
                <div className="details_item">
                  <span>{this.props.translate.contacts_address}</span>
                  <p>{this.state.data.address}</p>
                </div>
                <div className="details_item">
                  <span>{this.props.translate.contacts_email}</span>
                  <p><a href={"mailto:" + this.state.data.email}>{this.state.data.email}</a></p>
                </div>
                <div className="details_item">
                  <span>{this.props.translate.contacts_phone}</span>
                  <p>{this.state.data.phone}</p>
                </div>

                <div className="details_item details_item--hours">
                  <span>{this.props.translate.contacts_work}</span>

                  <div className="working_hours">
                    <div className="days">
                      <ul>
                        <li>{this.props.translate.contacts_day_monday}</li>
                        <li>{this.props.translate.contacts_day_tuesday}</li>
                        <li>{this.props.translate.contacts_day_thursday}</li>
                      </ul>
                    </div>
                    <div className="time">{this.props.translate.contacts_work_1}</div>
                  </div>

                  <div className="working_hours">
                    <div className="days">
                      <ul>
                        <li>{this.props.translate.contacts_day_wednesday}</li>
                        <li>{this.props.translate.contacts_day_friday}</li>
                      </ul>
                    </div>
                    <div className="time">{this.props.translate.contacts_work_2}</div>
                  </div>

                  <div className="working_hours">
                    <div className="days">
                      <ul>
                        <li>{this.props.translate.contacts_day_saturday}</li>
                        <li>{this.props.translate.contacts_day_sunday}</li>
                      </ul>
                    </div>
                    <div className="time">{this.props.translate.contacts_work_3}</div>
                  </div>
                </div>
                <p className="note">{this.props.translate.contacts_note}</p>
              </div>
            </div>

            <div className="col col-12 col--map d-lg-none">
              <div className="contacts__map">
                <Map lat={this.state.data.latitude} lng={this.state.data.longitude} />
              </div>
            </div>

          </div>
        </div>

        <Buy translate={this.props.translate}/>
      </div>
    );
  }
}

export default Contacts;
