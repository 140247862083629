import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Buy from '../partials/Buy'

import './Prices.css';

class Prices extends React.Component {
  constructor(){
    super();
    this.state = {
      meta: [],
      prices: []
    };

    this.updatePrice = this.updatePrice.bind(this);
  }

  componentDidMount() {
    axios.get(window.API + this.props.match.url)
      .then(result => {
        this.setState({
          meta: result.data.meta,
          prices: result.data.prices
        })
      })
      .catch(error => this.setState({
        error
      }));
  }

  formatPrice = (p) => {
    let price = p.split('.');
    return price.length === 1 ? p : price[0] + '<small>.'+price[1]+'</small>';
  }

  updatePrice = (index, price, indexOption) => {
    let prices = this.state.prices;
    prices[index].default_price = this.formatPrice(price);
    prices[index].default_option = indexOption;

    this.setState({
      prices: prices
    })
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>{this.state.meta.title}</title>
          <meta name="description" content={this.state.meta.description} />
          <meta name="keywords" content={this.state.meta.keywords} />
        </Helmet>

        <div className="prices">
          <div className="container">
            <h1>{this.state.meta.title}</h1>

            <div className="prices__plans">
              <div className="row row-eq-height">

                {this.state.prices.map((price, indexPrice) =>
                <div className="col-12 col-sm-12 col-md-6 col-lg-3" key={price.id}>
                  <div className="plan">
                    <div className="plan__details">
                      <h4>{price.title}</h4>
                      <p>{price.info}</p>
                    </div>

                    <div className="plan__options">
                      <ul>
                        {price.options.map((option, indexOption) =>
                        <li
                          className={indexOption === price.default_option ? 'checked' : ''}
                          key={option.id}
                          onClick={() => this.updatePrice(indexPrice,option.price,indexOption)}>{option.title}</li>
                        )}
                      </ul>
                    </div>

                    <div className="plan__price">
                      {price.show_count === '1' && (
                        <span className="x1">1 x</span>
                      )}
                      <div dangerouslySetInnerHTML={{__html: this.formatPrice(price.default_price)}}></div>
                      <span>€</span>
                    </div>
                  </div>
                </div>
                )}

              </div>
            </div>

            <div className="special_note">
              <p>{this.props.translate.price_contract_note}</p>
            </div>

            <div className="special_price">
              <h6>{this.props.translate.price_contract_title}</h6>
              <div>{this.props.translate.price_contract_price} <span>€</span></div>
            </div>

            <div className="special_price">
              <h6>{this.props.translate.price_seniors_title}</h6>
              <div>{this.props.translate.price_seniors_price} <span>€</span></div>
            </div>
          </div>
        </div>

        <Buy translate={this.props.translate}/>
      </div>
    );
  }
}

export default Prices;
