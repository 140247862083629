import React from 'react'
import Countdown from 'react-countdown-now';
import Parallax from "parallax-js";
import {Redirect} from "react-router-dom";
// Assets
import bgImg from "../../assets/img/sidebar-bg.svg";
// Style
import './Book.css'

// Session Countdown
const renderer = ({minutes, seconds, completed}) => {
	if (completed) {
		return (<Redirect to='/'/>);
	} else {
		return <span>{minutes}:{seconds}</span>;
	}
};

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timer: '',
			showTimer: false,
			user: JSON.parse(localStorage.getItem('user')) || false,
		}
		
		this.backToHome = this.backToHome.bind(this);
	}
	
	componentDidMount() {
		// Activate sidebar parallax
		this.parallax = new Parallax(this.scene);
		
		// Hide timer
		const path = window.location.pathname.split('/')
		const lastPathElem = path.pop()
		
		if (lastPathElem === 'choose-swimming-pool' || lastPathElem === 'choose-count' || lastPathElem === 'choose-date') {
			this.setState({
				showTimer: false
			})
		} else {
			this.setState({
				showTimer: true
			})
		}
		
		// Activate session Timer
		if (localStorage.getItem('timer')) {
			this.setState({
				timer: parseInt(localStorage.getItem('timer'))
			})
		}
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	backToHome() {
		// If registered User
		if (this.state.user.group === '0') {
			this.props.parentProps.history.push('/' + this.props.parentProps.language + '/profile')
		}
		
		// If Admin
		else if (this.state.user.group === '1') {
			this.props.parentProps.history.push('/' + this.props.parentProps.language + '/code-validation')
		}
		
		// If not registered user
		else {
			this.props.parentProps.history.push('/' + this.props.parentProps.language)
		}
	}
	
	render() {
		let translate = this.props.translate;
		
		let bgStyle = {
			height: this.props.bgHeight + 'vh',
			backgroundImage: 'url(' + bgImg + ')'
		};
		
		return (<div className="sidebar">
			
			<div className="absolute-bg" ref={el => this.scene = el}>
				<div className="img" data-depth={0.3} style={bgStyle}></div>
			</div>
			
			<div className="to-home">
				<p onClick={this.backToHome}>Uz sākumu</p>
			</div>
			
			{/*{this.state.showTimer && (*/}
			{/*	<div className="session">*/}
			{/*		<p>{translate.summary_session}</p>*/}
			{/*		<p className="session-time">*/}
			{/*			<Countdown date={this.state.timer + 899000} renderer={renderer}/>*/}
			{/*		</p>*/}
			{/*	</div>*/}
			{/*)}*/}
			
			<div className="top">
				<h1>{translate.sidebar_title}</h1>
				<p>{translate.sidebar_subtitle}</p>
			</div>
			
			<div className="bottom">
				<p>{translate.sidebar_choise}</p>
				
				<ul>
					{this.props.items.map((item, index) => <li key={index}>{item.name}</li>)}
				</ul>
				
				<span dangerouslySetInnerHTML={{__html: translate.sidebar_link}}></span>
			</div>
		</div>)
	}
}

export default Sidebar
