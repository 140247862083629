import React from 'react';
import ReservedVisits from "./Reserved-visits";
import SubscriptionsOk from "./Result-has-subscriptions"
import EmptySubscriptions from "./Result-empty";

class ResultSubscription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showResult: true,
		};
		this.hideResults = this.hideResults.bind(this);
	}
	
	hideResults() {
		this.setState({
			showResult: false,
		})
	}
	
	render() {
		const translate = this.props.translate;
		const ticket = this.props.ticketData;
		
		return (
			
			<div>
				
				<div className="admin-content">
					{this.state.showResult && (
						<h2>{translate.admin_content_result}</h2>
					)}
					
					{/* Subscription ok */}
					{(ticket.subscriptions[0].available > 0 || ticket.subscriptions[1].available > 0) && (
						<SubscriptionsOk translate={this.props.translate}
														 hideResultsContent={this.hideResults}
														 code={this.props.code}
														 selectDate={this.props.selectDate}
														 showCodeValidation={this.props.showCodeValidation}
														 changeLink={this.props.changeLink}
														 history={this.props.history}
														 ticket={ticket}/>
					)}
					
					{/* Subscription empty */}
					{ticket.subscriptions[0].available <= 0 && ticket.subscriptions[1].available <= 0 && (
						<EmptySubscriptions translate={this.props.translate}
																ticket={ticket}
																parentProps={this.props}/>
					)}
					
					{/* Reserved Visits */}
					{this.state.showResult && (
						<ReservedVisits translate={this.props.translate}
														showCodeValidation={this.props.showCodeValidation}
														code={this.props.code}
														tickets={ticket.tickets}
														reservations={ticket.reservations}/>
					)}
				</div>
			
			</div>
		);
	}
}

export default ResultSubscription;
