import React from "react";
import axios from "axios";
import moment from "moment"
import {Helmet} from "react-helmet";
import "./Admin.css";

import Sidebar from "./Sidebar";
import ResultSubscription from "./results/Result-subscription";
import ResultWrongTime from "./results/Result-wrong-time";
import ResultOkTicket from "./results/Result-ok-ticket";
import ResultLateTicket from "./results/Result-late-ticket";
import ResultUsedTicket from "./results/Result-used-ticket";
import Calendar from "../calendar/Calendar";

import {ReactComponent as ProfileImg} from "../../assets/img/profile.svg";
import {ReactComponent as Loader} from "../../assets/img/loader.svg";
import {ReactComponent as Time} from "../../assets/img/time.svg";
import checkGreen from "../../assets/img/check-green.svg";

let discount;

class CodeValidation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			code: '',
			codeError: false,
			codeValidation: true,
			resultOkSubscription: false,
			resultOkTicket: false,
			resultWrongTime: false,
			resultLateTicket: false,
			resultUsedTicket: false,
			showCalendarBlock: false,
			newNavLink: false,
			hideCalendar: true,
			showThnx: false,
			action: '',
			showSidebarInfo: false,
			currentTime: moment().format("HH:mm:ss"),
			dateTime: moment().format("YYYY-MM-DD HH:00:00"),
			dateTimeNexHour: moment().add(1, 'hour').format("YYYY-MM-DD HH:00:00"),
			largePoolCapacity: 0,
			largePoolReservations: 0,
			smallPoolCapacity: 0,
			smallPoolReservations: 0,
			registerChoiceLargePool: '0',
			registerChoiceSmallPool: '0',
			selectedRegisterPool: '1',
			showRegisterModal: false,
			ticketData: [],
			showLoader: false,
		};

		this.handleLogout = this.handleLogout.bind(this);
		this.handleCode = this.handleCode.bind(this);
		this.handleResult = this.handleResult.bind(this);
		this.handleCalendar = this.handleCalendar.bind(this);
		this.showDefaultCalendarInfo = this.showDefaultCalendarInfo.bind(this);
		this.handleDefaultView = this.handleDefaultView.bind(this);
		this.handleDynamicBackLink = this.handleDynamicBackLink.bind(this);
		// Register subscription large / small pool
		this.handleRegisterChangeLargePool = this.handleRegisterChangeLargePool.bind(this);
		this.handleRegisterChangeSmallPool = this.handleRegisterChangeSmallPool.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.handleRegisterModal = this.handleRegisterModal.bind(this);
	}

	componentDidMount() {
		// Logged in as admin
		localStorage.setItem('loggedIn', 'admin');

		// Clear previous / unfinished reservation data
		localStorage.removeItem('timer');
		localStorage.removeItem('price');
		localStorage.removeItem('priceId');
		localStorage.removeItem('discount-price');
		localStorage.removeItem('ticket-title');
		localStorage.removeItem('ticket-type');
		localStorage.removeItem('pool-title');
		localStorage.removeItem('product-id');
		localStorage.removeItem('selected-count');
		localStorage.removeItem('selected-count-title');
		localStorage.removeItem('selected-date');
		localStorage.removeItem('selected-time');
		localStorage.removeItem('selected-email');
		localStorage.removeItem('personal-code');
		localStorage.removeItem('sessionID');
		localStorage.removeItem('reserved-tickets');
		localStorage.removeItem('files');
		localStorage.removeItem('subscription_id')

		// Check if logged in user is admin
		const user = JSON.parse(localStorage.getItem('user'));

		if (user !== null && user.group === '1') {
			this.setState({
				token: user.token
			})
		} else {
			this.props.history.push('/')
		}

		// Current time
		this.interval = setInterval(() => {
			this.setState({
				currentTime: moment().format("HH:mm:ss")
			})
		}, 1000);

		// Define self var to use in listeners scope
		let self = this;

		// Listen for barcode scanner input
		let inputString = '';

		document.addEventListener('keydown', function (e) {
			if (e.key !== 'Enter') {
				inputString = inputString + e.key;
			} else {
				self.setState({
					code: inputString
				});
				self.handleCode(e);
				inputString = '';
			}
		});

		this.handlePoolCapacity();
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	handlePoolCapacity() {
		let self = this;

		// Ge pool capacity, reservations
		axios.get(window.DEV_API + "api/products/")
			.then(response => {
				self.setState({
					largePoolCapacity: response.data.products[0].capacity,
					largePoolReservations: response.data.products[0].reservations,
					smallPoolCapacity: response.data.products[1].capacity,
					smallPoolReservations: response.data.products[1].reservations
				})
			}).catch(error => console.log(error));
	}

	handleLogout(e) {
		e.preventDefault();

		axios.post(window.DEV_API + 'api/user/logout', {}, {
			headers: {'Authorization': this.state.token}
		}).then(function () {
			window.open(window.location, "_self");
			localStorage.clear();
		}).catch(error => console.log(error))
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
	};

	handleCode = e => {
		e.preventDefault();

		this.setState({
			showLoader: true
		});

		if (this.state.code.length < 8) {
			this.setState({
				codeError: true,
				showLoader: false
			})
		} else {
			this.setState({
				codeError: false
			});

			let self = this;

			axios.post(window.DEV_API + 'api/admin/validate', {
				number: this.state.code
			}, {
				headers: {'Authorization': this.state.token}
			}).then(function (response) {
				
				// Check if subscription is valid
				if (response.data.error === 'not_valid') {
					self.setState({
						codeError: true,
						showLoader: false
					});
				} else {
					// Clear interval
					clearInterval(self.interval);

					self.setState({
						ticketData: response.data.data,
						showLoader: false
					});

					localStorage.setItem('codeValidationView', true);

					const type = response.data.data.type;

					if (type === "subscription" || type === "single" || type === "personal") {
						self.handleResult('subscription');
						self.setState({
							code: response.data.data.subscriptions[0].id
						})
					} else {
						// Type reservation ( Single ticket )
						self.handleResult(response.data.data.status);
					}
				}

			}).catch(error => console.log(error))
		}
	};

	handleResult(result) {
		this.setState({
			showThnx: false
		});

		switch (result) {
			case 'none':
				this.setState({
					codeValidation: false,
					resultOkTicket: false,
					resultOkSubscription: false,
					resultWrongTime: false,
					resultLateTicket: false,
					resultUsedTicket: false,
					showCalendarBlock: false,
				});
				break;
			case 'codeValidation':
				this.setState({
					codeValidation: true,
					resultOkTicket: false,
					resultOkSubscription: false,
					resultWrongTime: false,
					resultLateTicket: false,
					showSidebarInfo: false,
					resultUsedTicket: false,
					showCalendarBlock: false
				});

				// Refresh pool data
				this.handlePoolCapacity();

				localStorage.removeItem('codeValidationView');
				localStorage.removeItem('selected-date');
				localStorage.removeItem('selected-time');
				localStorage.removeItem('ticket-type');
				localStorage.removeItem('product-id');
				break;
			case 'valid':
				this.setState({resultOkTicket: true, showSidebarInfo: true, codeValidation: false});
				break;
			case 'early':
				this.setState({resultWrongTime: true, showSidebarInfo: true, codeValidation: false});
				break;
			case 'late':
				this.setState({resultLateTicket: true, showSidebarInfo: true, codeValidation: false});
				break;
			case 'used':
				this.setState({resultUsedTicket: true, showSidebarInfo: true, codeValidation: false});
				break;
			case 'subscription':
				this.setState({resultOkSubscription: true, showSidebarInfo: true, codeValidation: false});
				break;
			default:
				break
		}
	}

	handleCalendar(action) {
		// Hide any result and show calendar
		this.handleResult('none');

		this.setState({
			showCalendarBlock: true,
			action: action
		})
	}
	
	showDefaultCalendarInfo(action, selectedPool) {
		
		// Hide any result and show calendar
		this.handleResult('none');
		
		let self = this;
		
		setTimeout(function () {
			self.setState({
				showCalendarBlock: true,
				action: action
			})
		},50)
	
		localStorage.setItem('codeValidationView', true)
		localStorage.setItem('ticket-type', 'single');
		localStorage.setItem('product-id', selectedPool);
	}

	onChangeDate(newDate) {
		localStorage.setItem('selected-date', newDate);

		this.setState({
			selectedDate: newDate
		})
	}

	onChangeLink(newLink) {
		if (newLink === true) {
			this.setState({
				newNavLink: true,
				hideCalendar: false
			})
		}
	}

	handleDynamicBackLink() {
		if (localStorage.getItem('codeValidationView')) {
			this.handleResult('codeValidation');
		} else {
			this.props.history.push('select-admin')
		}
	}

	handleDefaultView() {
		this.handleResult('none');

		this.setState({
			showThnx: true,
			newNavLink: false,
			hideCalendar: true,
			showCalendarBlock: false
		})
	}

	// Register subscription large / small pool
	handleRegisterChangeLargePool(e) {
		this.setState({
			registerChoiceLargePool: e.target.value
		});
	}

	handleRegisterChangeSmallPool(e) {
		this.setState({
			registerChoiceSmallPool: e.target.value
		});
	}

	handleRegister(poolId, e) {
		e.preventDefault();

		this.setState({
			selectedRegisterPool: poolId,
			showRegisterModal: true
		})

	}

	handleRegisterModal() {

		if (this.state.selectedRegisterPool === 1) {
			discount = this.state.registerChoiceLargePool
		} else {
			discount = this.state.registerChoiceSmallPool
		}

		// Confirm Visit
		axios.post(window.DEV_API + 'api/admin/register', {
			product_id: this.state.selectedRegisterPool,
			discount: discount,
			date_time: this.state.dateTime
		}, {
			headers: {'Authorization': this.state.token}
		}).then(response => {
			this.handleResult('none');

			if (!response.data.error) {
				this.setState({
					showRegisterModal: false,
					showThnx: true,
					action: 'ticket'
				})
			} else {
				alert(response.data.error);
			}

		}).catch(error => console.log(error))

	}

	handleTicketsPurchase = () => {
		this.props.history.push('/' + this.props.language + '/book-visit');
	};

	render() {
		const translate = this.props.translate;

		return (
			<div>
				<Helmet>
					<title>{translate.admin_code_validation_title}</title>
				</Helmet>

				<div className="container-fluid no-padding">
					<div className="admin-dashboard">
						<div className="admin-menu mobile">
							<a href={'/'}
								 onClick={this.handleLogout}
								 className="profile--link inner">
									<span>
										{translate.profile_body_logout}
									</span>
								<ProfileImg/>
							</a>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-4 col-md-12">
							<div className="sidebar-arrow">
								{!this.state.newNavLink
									? <p className="back-arrow" onClick={this.handleDynamicBackLink}></p>
									: <p className="back-arrow"
											 onClick={() => {this.setState({hideCalendar: true, newNavLink: false})}}></p>}
							</div>

							<Sidebar
								showCalendar={this.showDefaultCalendarInfo}
								showInfo={this.state.showSidebarInfo}
								translate={this.props.translate}/>
						</div>

						<div className="col-lg-8 col-md-12 admin-dashboard">
							<div className="admin-menu desktop">
								<a href={'/'}
									 onClick={this.handleLogout}
									 className="profile--link inner">
									<span>{translate.profile_body_logout}</span>
									<ProfileImg/>
								</a>
							</div>

							{/* Code Validation input*/}
							{this.state.codeValidation && (
								<div className="admin-content code-validation">

									<div className="book-visit">
										<h2>{translate.admin_content_buy_ticket_subscription}</h2>

										<div className="btn-wrap">

											<button className="btn btn-red"
															onClick={this.handleTicketsPurchase}>
												{translate.admin_content_buy_ticket_subscription}
											</button>

										</div>
									</div>

									<h2>{translate.admin_code_validation_title}</h2>
									<p className="subtitle">{translate.admin_code_validation_subtitle}</p>

									<form onSubmit={this.handleCode} noValidate>

										<div className={this.state.codeError ? "form-group error" : "form-group"}>
											<input className="form-control"
														 type="text"
														 id="code"
														 name="code"
														 value={this.state.code}
														 onChange={this.handleChange('code')}
														 required/>

											<label className="form-control-placeholder"
														 htmlFor="code">{translate.form_admin_code}</label>

											{this.state.codeError && (
												<span className="error-msg">{translate.form_admin_code_error}</span>
											)}

											{this.state.showLoader && (
												<Loader className="loader"/>
											)}

										</div>

										<button className="btn btn-transparent"> {translate.form_admin_validation_btn_accept}</button>

									</form>

									<div className="pool-availability">
										<h2>{translate.admin_content_pool_availability}</h2>
										<p className="availability__time">
											<Time/>{translate.admin_content_time} {this.state.currentTime}
										</p>

										<div className="pool">
											<div className='pool__visits'>
												<span>{translate.admin_content_large_pool}</span>
												<div>
													<div className="available-visits">
														<h4>{translate.admin_content_free_places}</h4>
														<p>{this.state.largePoolCapacity - this.state.largePoolReservations}</p>
													</div>

													<div className="available-visits">
														<p>{translate.admin_content_reserved_places}</p>
														<p>{this.state.largePoolReservations}</p>
													</div>

													<form className="register" onSubmit={e => this.handleRegister(1, e)}>
														<ul className="radio_btns">
															<li>
																<label>
																	<input
																		type="radio"
																		value="0"
																		checked={this.state.registerChoiceLargePool === "0"}
																		onChange={this.handleRegisterChangeLargePool}
																	/>
																	{translate.admin_content_without_discount}
																	<span></span>
																</label>
															</li>

															<li>
																<label>
																	<input
																		type="radio"
																		value="1"
																		checked={this.state.registerChoiceLargePool === "1"}
																		onChange={this.handleRegisterChangeLargePool}
																	/>
																	{translate.admin_content_with_discount}
																	<span></span>
																</label>
															</li>

														</ul>

														<div className="btn-wrap">
															<button className="btn btn-red">
																{translate.common_register}
															</button>
														</div>
													</form>

												</div>
											</div>

											<div className={'pool__visits'}>
												<span>{translate.admin_content_small_pool}</span>
												<div>
													<div className="available-visits">
														<h4>{translate.admin_content_free_places}</h4>
														<p>{this.state.smallPoolCapacity - this.state.smallPoolReservations}</p>
													</div>

													<div className="available-visits">
														<p>{translate.admin_content_reserved_places}</p>
														<p>{this.state.smallPoolReservations}</p>
													</div>
													<form className="register" onSubmit={e => this.handleRegister(2, e)}>
														<ul className="radio_btns">
															<li>
																<label>
																	<input
																		type="radio"
																		value="0"
																		checked={this.state.registerChoiceSmallPool === "0"}
																		onChange={this.handleRegisterChangeSmallPool}
																	/>
																	{translate.admin_content_without_discount}
																	<span></span>
																</label>
															</li>
															<li>
																<label>
																	<input
																		type="radio"
																		value="1"
																		checked={this.state.registerChoiceSmallPool === "1"}
																		onChange={this.handleRegisterChangeSmallPool}
																	/>
																	{translate.admin_content_with_discount}
																	<span></span>
																</label>
															</li>

														</ul>

														<div className="btn-wrap">
															<button className="btn btn-red">
																{translate.common_register}
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>

								</div>
							)}

							{this.state.resultOkSubscription && (
								<ResultSubscription
									ticketData={this.state.ticketData}
									code={this.state.code}
									selectDate={this.handleCalendar}
									showCodeValidation={this.handleResult}
									changeLink={this.onChangeLink.bind(this)}
									translate={this.props.translate} {...this.props}/>
							)}

							{this.state.resultOkTicket && (
								<ResultOkTicket
									ticketData={this.state.ticketData}
									selectDate={this.handleCalendar}
									showCodeValidation={this.handleResult}
									translate={this.props.translate}/>
							)}

							{this.state.resultWrongTime && (
								<ResultWrongTime
									ticketData={this.state.ticketData}
									showCodeValidation={this.handleResult}
									translate={this.props.translate}/>
							)}

							{this.state.resultLateTicket && (
								<ResultLateTicket
									ticketData={this.state.ticketData}
									showCodeValidation={this.handleResult}
									translate={this.props.translate}/>
							)}

							{this.state.resultUsedTicket && (
								<ResultUsedTicket
									ticketData={this.state.ticketData}
									showCodeValidation={this.handleResult}
									translate={this.props.translate}/>
							)}

							{this.state.showCalendarBlock && (
								<div className="admin-content">
									<Calendar
										actionFromAdmin={true}
										code={this.state.code}
										returnToAdmin={this.handleDefaultView}
										lang={this.props.match.params.lang}
										translate={translate}
										changeDate={this.onChangeDate.bind(this)}
										changeLink={this.onChangeLink.bind(this)}
										hideCalendar={this.state.hideCalendar}
										navLink={this.state.newNavLink}
										parentProps={this.props}
									/>
								</div>
							)}

							{/* Result THNX */}
							{this.state.showThnx && (
								<div className="admin-content">
									<h2>{translate.common_thnx}</h2>

									<div className="result result__thnx">
										<p>
											<img src={checkGreen} alt=""/>

											{this.state.action === 'subscription' && (
												translate.admin_content_subscription_acepted
											)}

											{this.state.action === 'ticket' && (
												translate.admin_content_ticket_is_registred
											)}
										</p>

										<p className="date">
											{localStorage.getItem('selected-date') && (
												localStorage.getItem('selected-date') + ', ' + localStorage.getItem('selected-time')
											)}
										</p>

										<button
											onClick={() => this.handleResult('codeValidation')}
											className="btn-transparent" type="button">
											{translate.common_go_back_to_home}
										</button>
									</div>
								</div>
							)}

							{/* Accept actions modal */}
							{this.state.showRegisterModal && (
								<div className="modal__void">
									<div className="modal__content">
										<h4>{translate.admin_content_register_ticket}</h4>
										<p>{translate.admin_content_register_ticket_text}</p>

										<div className="btn-wrap">
											<button type="button" className="btn"
															onClick={this.handleRegisterModal}>
												{translate.common_yes}
											</button>

											<button type="button" className="btn-transparent"
															onClick={() => this.setState({showRegisterModal: !this.state.showRegisterModal})}>
												{translate.common_no}
											</button>
										</div>
									</div>
								</div>
							)}

						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default CodeValidation
