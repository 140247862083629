import React from 'react';
import axios from "axios";

class reservedVisits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: JSON.parse(localStorage.getItem('user')),
			reservation_id: '',
			product_id: '',
			reservation_type: '',
			showVoidModal: false,
			showRegisterModal: false,
			reservations: [],
			tickets: [],
			showSpaTickets: false,
			showTitle: false,
			showLargePoolReservations: false,
			showSmallPoolReservations: false
		};
		
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleVoid = this.handleVoid.bind(this);
		this.handleReservations = this.handleReservations.bind(this);
	}
	
	componentDidMount() {
		this.setState({
			reservations: this.props.reservations,
			tickets: this.props.tickets
		});
		
		this.handleReservations();
		
		this.ticketReloadInterval = setInterval(() => {
			this.handleReservations();
		}, 1800000);
		
	}
	
	componentWillUnmount() {
		clearInterval(this.ticketReloadInterval);
	}
	
	// Get reservations
	handleReservations() {
		
		// Check if reservations are with values
		if (this.props.reservations.length !== 0) {
			this.setState({
				showTitle: true
			})
		}
		
		if (this.props.reservations.filter(reservation => reservation.product_id === '1').length !== 0) {
			this.setState({
				showLargePoolReservations: true
			})
		}
		
		if (this.props.reservations.filter(reservation => reservation.product_id === '2').length !== 0) {
			this.setState({
				showSmallPoolReservations: true
			})
		}
		
		if ( this.props.tickets !== null && this.props.tickets !== undefined) {
			this.setState({
				showSpaTickets: true
			})
		}
		
		let self = this;
		
		axios.post(window.DEV_API + 'api/admin/validate', {
			number: this.props.code
		}, {
			headers: {'Authorization': this.state.user.token}
		}).then(function (response) {
			
			// Check if subscription is valid
			if (!response.data.error) {
				self.setState({
					reservations: response.data.data.reservations,
					tickets: response.data.data.tickets
				});
			} else {
				alert("Error " + response.data.error)
			}
			
		}).catch(error => console.log(error))
	}
	
	// Register
	handleConfirmModal(id, type) {
		this.setState({
			showRegisterModal: true,
			reservation_id: id,
			reservation_type: type
		})
	}
	
	handleConfirm() {
		// Confirm Visit
		
		if (this.state.reservation_type === 'reservation') {
			axios.post(window.DEV_API + 'api/admin/confirm', {
				reservation_id: this.state.reservation_id
			}, {
				headers: {'Authorization': this.state.user.token}
			}).then(response => {
				if (!response.data.error) {
					
					this.handleReservations();
					
					this.setState({
						showRegisterModal: false
					})
				} else {
					alert("Error " + response.data.error)
				}
				
			}).catch(error => console.log(error))
		} else {
			axios.post(window.DEV_API + 'api/admin/confirm', {
				ticket_id: this.state.reservation_id
			}, {
				headers: {'Authorization': this.state.user.token}
			}).then(response => {
				if (!response.data.error) {
					
					this.handleReservations();
					
					this.setState({
						showRegisterModal: false
					})
				} else {
					alert("Error " + response.data.error)
				}
				
			}).catch(error => console.log(error))
		}
		
	}
	
	// Void
	handleVoidModal(id, product_id) {
		this.setState({
			showVoidModal: true,
			reservation_id: id,
			product_id: product_id
		});
	}
	
	handleVoid() {
		// Cancel Visit
		axios.post(window.DEV_API + 'api/admin/cancel', {
			reservation_id: this.state.reservation_id,
			product_id: this.state.product_id
		}, {
			headers: {'Authorization': this.state.user.token}
		}).then(response => {
			
			if (!response.data.error) {
				
				this.handleReservations();
				
				this.props.showCodeValidation('codeValidation');
				
				this.setState({
					showVoidModal: false,
				})
				
			} else {
				alert("Error " + response.data.error)
			}
			
		}).catch(error => console.log(error))
	}
	
	render() {
		const translate = this.props.translate;
		
		return (
			<div className="reserved-visits">
				
				{this.state.showTitle && (
					<h2>{translate.admin_content_reserved_visits}</h2>
				)}
				
				
				{this.state.showLargePoolReservations && (
					<span>{translate.admin_content_large_pool}</span>
				)}
				
				{this.state.showLargePoolReservations && (
					<div className="visits">
						{
							this.state.reservations.filter(reservation => reservation.product_id === '1').map((reservation, index) =>
								<div className={'item' + (reservation.checkin === 'valid' ? ' valid' : '')} key={index}>
									<h4>{reservation.date}</h4>
									<p>{reservation.family_count > 0 ? translate.choose_product_family_title : reservation.product_title}</p>
									{reservation.family_count > 0 && (
										<p>{translate.reservation_family_people_count} {reservation.family_count}</p>
									)}
									
									{reservation.checkin === 'valid' && (
										<div className="btns">
											<button className="btn-transparent"
															onClick={e => this.handleConfirmModal(reservation.id, 'reservation')}>
												{translate.common_register}
											</button>
										</div>
									)}
									
									<div className="btns">
										<p onClick={e => this.handleVoidModal(reservation.id, 1)}>
											{translate.common_void}
										</p>
									</div>
								
								</div>
							)
						}
					</div>
				)}
				
				{this.state.showSmallPoolReservations && (
					<span>{translate.admin_content_small_pool}</span>
				)}
				
				{this.state.showSmallPoolReservations && (
					<div className="visits">
						{
							this.state.reservations.filter(reservation => reservation.product_id === '2').map((reservation, index) =>
								<div className={'item' + (reservation.checkin === 'valid' ? ' valid' : '')} key={index}>
									<h4>{reservation.date}</h4>
									<p>{reservation.product_title}</p>
									
									{reservation.checkin === 'valid' && (
										<div className="btns">
											<button className="btn-transparent"
															onClick={e => this.handleConfirmModal(reservation.id, 'reservation')}>
												{translate.common_register}
											</button>
										</div>
									)}
									
									<div className="btns">
										<p onClick={e => this.handleVoidModal(reservation.id, 2)}>
											{translate.common_void}
										</p>
									</div>
								
								</div>
							)
						}
					</div>
				)}
				
				{this.state.showSpaTickets.length > 0 && (
					<span>{translate.profile_body_spa}</span>
				)}
				
				{this.state.showSpaTickets && (
					<div className="visits">
						{
							this.state.tickets.map((ticket, index) =>
								<div className="item valid" key={index}>
									<h4>{ticket.date}</h4>
									<p>{ticket.title}</p>
									
									<div className="btns">
										<button className="btn-transparent"
														onClick={e => this.handleConfirmModal(ticket.id, 'ticket')}>
											{translate.common_register}
										</button>
									</div>
								</div>
							)
						}
					</div>
				)}
				
				{/* Void modal*/}
				{this.state.showVoidModal && (
					<div className="modal__void">
						
						<div className="modal__content">
							<h4>{translate.admin_content_void_ticket}</h4>
							<p>{translate.admin_content_void_ticket_text}</p>
							
							<div className="btn-wrap">
								<button type="button" className="btn"
												onClick={this.handleVoid}>
									{translate.common_yes}
								</button>
								
								<button type="button" className="btn-transparent"
												onClick={() => this.setState({showVoidModal: !this.state.showVoidModal})}>
									{translate.common_no}
								</button>
							</div>
						
						</div>
					
					</div>
				)}
				
				{/* Register Modal*/}
				{this.state.showRegisterModal && (
					<div className="modal__void">
						<div className="modal__content">
							<h4>{translate.admin_content_register_ticket}</h4>
							<p>{translate.admin_content_register_ticket_text}</p>
							
							<div className="btn-wrap">
								<button type="button" className="btn"
												onClick={this.handleConfirm}>
									{translate.common_yes}
								</button>
								
								<button type="button" className="btn-transparent"
												onClick={() => this.setState({showRegisterModal: !this.state.showRegisterModal})}>
									{translate.common_no}
								</button>
							</div>
						
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default reservedVisits;
