import React from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";

// Style
import './Book.css';

// Components
import Sidebar from "./Sidebar";

class ChooseSwimmingPool extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedBookType: localStorage.getItem('ticket-type'),
			user: JSON.parse(localStorage.getItem('user')) || false,
		};
		this.selectedPool = this.selectedPool.bind(this);
		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {
		if (!this.state.selectedBookTypeTitle) {
			this.props.history.push('/' + this.props.language +'/book-visit');
		}

		axios.get(window.DEV_API + "api/products/")
			.then(response => {
					if (!response.data.error) {
						this.setState({
							data: response.data.products
						});
					} else {
						alert(response.data.error)
					}
				}
			).catch(error => console.log(error));
		// window.addEventListener('popstate', this.handleLink);
	}

	selectedPool = (selectedPoolID, selectedPoolTitle) => {
		localStorage.setItem('pool-title', selectedPoolTitle);
		localStorage.setItem('product-id', selectedPoolID);

		this.props.history.push('choose-count');
	};

	handleLink = () => {

		if (this.state.user) {
			if (this.state.user.group === "1") {
				this.props.history.push('code-validation')
			} else {
				this.props.history.push('/' + this.props.language + '/profile')
			}
		} else {
			this.props.history.push('/' + this.props.language +'/book-visit')
		}

		window.removeEventListener('popstate', this.handleLink)
	}

	render() {
		const translate = this.props.translate;

		const choise = [
			{
				name: this.state.selectedBookTypeTitle
			}
		];

		return (<div>
			<Helmet>
				<title>{translate.choose_pool_title}</title>
			</Helmet>

			<div className="container-fluid">
				<div className="row">

					<div className="sidebar-wrap col-lg-5 col-md-12">
						<div className="sidebar-arrow">
							<p className="back-arrow" onClick={e => this.handleLink()}></p>
						</div>

						<Sidebar bgHeight={25} parentProps={this.props} translate={this.props.translate} items={choise}/>
					</div>
					<div className="content col-lg-7 col-md-12">
						<div className="row active-step">
							<div className="col-sm-3 active"></div>
							<div className="col-sm-3"></div>
							<div className="col-sm-3"></div>
							<div className="col-sm-3"></div>
							<div className="col-sm-3"></div>
						</div>

						<div className="header">
							<div className="row">
								<h2>{translate.choose_pool_title}</h2>
								<p>{translate.choose_pool_intro}</p>
							</div>
						</div>

						<div className="choose-pool row">
							{
								this.state.data.filter(item => item.type !== '3').map(item =>
									<div
										key={item.id}
										className="pool col-sm-12 col-lg-6"
										onClick={e => this.selectedPool(item.id, item.title)}>

										<h3>{item.title}</h3>

										<ul>
											{
												item.options.map(option => <li key={option.id}>
                        <span><img src={option.image} alt=""/>
                        </span>
													{option.title}
												</li>)
											}
										</ul>
									</div>)
							}
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}

export default ChooseSwimmingPool;
