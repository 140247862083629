import React from 'react';
import {ReactComponent as User} from "../../../assets/img/user.svg";
import {ReactComponent as Email} from "../../../assets/img/email.svg";
import {ReactComponent as Phone} from "../../../assets/img/phone.svg";
import axios from "axios";

class TabProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			successMsg: false,
			
			name: '',
			email: '',
			phone: '',
			token: '',
			
			nameError: false,
			emailError: false,
			phoneError: false,
			
			formValid: false,
			nameValid: false,
			emailValid: false,
			phoneValid: true,
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.showModal = this.showModal.bind(this);
		
	}
	
	componentDidMount() {
		
		const user = JSON.parse(localStorage.getItem('user'));
		
		this.setState({
			name: user.name,
			email: user.email,
			phone: user.phone,
			token: user.token
		});
		
		this._getStorageData();
	}
	
	
	_getStorageData = async () => {
		try {
			const value = await JSON.parse(localStorage.getItem('user'));
			if (value !== null) {
				this.validateField('name', value.name);
				this.validateField('email', value.email);
				this.validateField('phone', value.phone);
				
			}
		} catch (error) {
			console.log(error)
		}
	};
	
	
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
		
		this.validateField(name, event.target.value)
	}
	
	validateField(fieldName, value) {
		
		let nameValid = this.state.nameValid;
		let phoneValid = this.state.phoneValid;
		let emailValid = this.state.emailValid;
		
		switch (fieldName) {
			case "name":
				nameValid = value.length >= 2;
				break;
			case "email":
				emailValid = !!value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
				break;
			case "phone":
				if (value.length > 0) {
					phoneValid = !!value.match(/^\d{8,12}$/);
				} else {
					phoneValid = true;
				}
				break;
			default:
				break;
		}
		
		this.setState({
			nameValid: nameValid,
			emailValid: emailValid,
			phoneValid: phoneValid,
		}, this.validateForm)
	}
	
	validateForm() {
		let form = this.state;
		
		this.setState({
			formValid: form.nameValid && form.emailValid && form.phoneValid
		})
	}
	
	handleSubmit = event => {
		event.preventDefault();
		
		if (!this.state.formValid) {
			this.setState({
				nameError: !this.state.nameValid,
				emailError: !this.state.emailValid,
				phoneError: !this.state.phoneValid,
			})
		} else {
			
			let self = this;
			
			axios.post(window.DEV_API + 'api/user/update', {
					name: this.state.name,
					email: this.state.email,
					phone: this.state.phone,
					
				}, {
					headers: {'Authorization': this.state.token}
				})
				
				.then(function (response) {
					
					localStorage.setItem('user', JSON.stringify(response.data.user));
					
					self.setState({
						successMsg: true
					})
				})
				
				.catch(error => {
					console.log(error)
				})
			
			this.setState({
				nameError: false,
				emailError: false,
				phoneError: false,
			})
		}
		
	};
	
	showModal() {
		this.setState({
			showModal: !this.state.showModal
		})
	}
	
	handleDelete() {
		axios.post(window.DEV_API + 'api/user/delete', {}, {
				headers: {'Authorization': this.state.token}
			}).then(function (response) {
				if (!response.data.errors) {
					window.open(window.location, "_self");
					localStorage.clear()
				}
			})
	}
	
	render() {
		const formErrors = this.state;
		const translate = this.props.translate;
		
		return (
			
			<div>
				<form onSubmit={this.handleSubmit} noValidate>
					
					<div className="inputs">
						<div className={formErrors.nameError ? "form-group error" : "form-group"}>
							<input className="form-control user"
										 type="text"
										 id="fullname"
										 value={this.state.name}
										 onChange={this.handleChange('name')}
										 required/>
							
							<label className="form-control-placeholder"
										 htmlFor="fullname">{translate.form_name}</label>
							
							<div className="icon">
								<User/>
							</div>
							
							{formErrors.nameError && (
								<span className="error-msg">{translate.form_error_name}</span>
							)}
						</div>
						
						<div className={formErrors.emailError ? "form-group error" : "form-group"}>
							<input className="form-control email"
										 type="email"
										 id="email"
										 value={this.state.email}
										 onChange={this.handleChange('email')}
										 required/>
							
							<label className="form-control-placeholder"
										 htmlFor="email">{translate.form_email}</label>
							
							<div className="icon">
								<Email/>
							</div>
							
							{formErrors.emailError && (
								<span className="error-msg">{translate.form_error_email}</span>
							)}
						</div>
						
						<div className={formErrors.phoneError ? "form-group error" : "form-group"}>
							<input className="form-control phone"
										 type="text"
										 id="phone"
										 value={this.state.phone}
										 onChange={this.handleChange('phone')}
										 required/>
							
							<label className="form-control-placeholder"
										 htmlFor="phone">{translate.form_phone}</label>
							
							<div className="icon">
								<Phone/>
							</div>
							
							{formErrors.phoneError && (
								<span className="error-msg">{translate.form_error_phone}</span>
							)}
							
							{formErrors.successMsg && (
								<span className="success-msg">{translate.form_success_profile_edit}</span>
							)}
						
						
						</div>
					
					</div>
					
					<div className="btn-wrap">
						<button className="btn form-button">
							{translate.common_save}
						</button>
						
						<p onClick={this.showModal}>
							{translate.profile_delete_profile}
						</p>
					
					</div>
				</form>
				
				{this.state.showModal && (
					<div className="modal__delete">
						
						<div className="modal__content">
							<h4>{translate.form_modal_title}</h4>
							<p>{translate.form_modal_content}</p>
							
							<div className="btn-wrap">
								
								<button className="btn"
												onClick={this.showModal}>
									{translate.common_cancel}
								</button>
								
								<button
									onClick={this.handleDelete}
									className="btn-transparent">
									{translate.common_yes}
								</button>
							
							</div>
						</div>
					
					</div>
				)}
			
			</div>
		);
	}
}


export default TabProfile;
