import React from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import  Buy  from '../partials/Buy.js'

import './About.css';

class About extends React.Component {
  constructor(){
    super();
    this.state = {
      meta: [],
      about: [],
      sections: []
    };
  }

  componentDidMount() {
    axios.get(window.API + this.props.match.url)
      .then(result => {
        this.setState({
          meta: result.data.meta,
          about: result.data.about,
          sections: result.data.sections
        })
      })
      .catch(error => this.setState({
        error
      }));
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>{this.state.meta.title}</title>
          <meta name="description" content={this.state.meta.description} />
          <meta name="keywords" content={this.state.meta.keywords} />
        </Helmet>

        <div className="about">
          <div className="container">
            <h1>{this.state.meta.title}</h1>

            <div className="about__head">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <p className="strong">{this.state.about.small_text_1}</p>
                </div>
                <div className="col-12 col-sm-4">
                  <p>{this.state.about.small_text_2}</p>
                </div>

                {this.state.about.schedule_label &&
                <div className="col-12 col-sm-4">
                  <div className="schedule_link">
                    <a href={this.state.about.schedule_url} style={{paddingBottom:"19px"}} target="_blank" rel="noopener noreferrer">
                      <h5>{this.state.about.schedule_label}</h5>
                    </a>
                  </div>
                </div>
                }
              </div>
            </div>

            <div className="about__topics">
              <div className="row">
                {this.state.sections.map(topic =>
                <div className="col-12 col-sm-12 col-md-6 col-lg-3" key={topic.id}>
                  <NavLink to={topic.link}>
                    <div className="topic">
                      <img src={topic.image} alt="" />
                      <span className="type">{topic.type}</span>
                      <h6>{topic.title}</h6>
                    <span className="more">{this.props.translate.more}</span>
                    </div>
                  </NavLink>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Buy translate={this.props.translate}/>

      </div>
    );
  }
}

export default About;
