import React from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import Parallax from 'parallax-js'

import './Book.css';

import bgImg from '../../assets/img/book-visit.svg';

class BookVisit extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			data: []
		}
	}
	
	componentDidMount() {
		axios.get(window.DEV_API + "api/prices/")
			.then(response => {
				if (!response.data.error) {
					this.setState({
						data: response.data.prices
					});
				} else {
					alert(response.data.error)
				}
			}).catch(error => console.log(error));
		
		document.body.classList.add('menu--transparent');
		
		this.parallax = new Parallax(this.scene)
	}
	
	componentWillUnmount() {
		document.body.classList.remove('menu--transparent');
		this.parallax.disable()
	}
	
	chooseType = (title, type, product, priceID, price, discount) => {
		localStorage.setItem('ticket-title', title);
		localStorage.setItem('ticket-type', type);
		localStorage.setItem('product-id', product);
		localStorage.setItem('priceId', priceID);
		localStorage.setItem('price', price);
		localStorage.setItem('discount-price', discount);
		
		if (type === 'spa' || type === 'family') {
			this.props.history.push('choose-count');
		} else {
			this.props.history.push('choose-swimming-pool');
		}
	};
	
	
	render() {
		const translate = this.props.translate;
		const uniqueResult = [];
		const map = new Map();
		
		for (const item of this.state.data) {
			if(!map.has(item.type)){
				map.set(item.type, true);
				uniqueResult.push(item);
			}
		}
		
		return (<div className="book--view">
			<Helmet>
				<title>{translate.choose_product_title}</title>
			</Helmet>
			
			<div className="container">
				<div className="absolute-bg" ref={el => this.scene = el}>
					<img data-depth="0.5" src={bgImg} alt="background"/>
				</div>
				
				<div className="row">
					<div className="col-xs-12 description">
						<h1>{translate.choose_product_title}</h1>
						<h5>{translate.choose_product_intro}</h5>
					</div>
				</div>
				
				{
					this.state.data.length > 0 &&
					<div className="row">
						<div className="col-xs-12 visit">
							<div className="row">
								{
									uniqueResult.map((item, index) =>
										
										<div className="col-sm-12 col-lg-3 link-wrap" key={index}
												 onClick={() => this.chooseType(item.title, item.type, item.product_id, item.id, item.price, item.discount)}>
											
											<div className="link">
												<h4>{item.title}</h4>
												<span>{translate.choose_product_without_discount}</span>
												<h6>{item.price} {translate.choose_product_eur}</h6>
												<p>{item.info}</p>
												<button>{translate.choose_product_buy}</button>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
				}
			</div>
		</div>);
	}
}

export default BookVisit;
