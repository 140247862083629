import React from 'react';
import {Helmet} from "react-helmet";
import Parallax from 'parallax-js';
import print from 'print-js'

// Style
import './Book.css';
import bgImg from "../../assets/img/book-visit.svg";

class Confirmation extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedTicketType: localStorage.getItem('ticket-type'),
			user: JSON.parse(localStorage.getItem('user')) || false,
			email: localStorage.getItem('selected-email'),
			personalCode: localStorage.getItem('personal-code'),
			files: JSON.parse(localStorage.getItem("files")) || false,
			downloadLink: '',
			status: '',
		};
		this.clearLocalstorage = this.clearLocalstorage.bind(this);
		this.downloadTicket = this.downloadTicket.bind(this);
		this.backToHome = this.backToHome.bind(this);
	}
	
	componentDidMount() {
		
		// Redirect to home page if selected data are empty
		if (!this.state.selectedBookTypeTitle || !this.state.selectedTicketType) {
			this.props.history.push('/' + this.props.language + '/book-visit');
		}
		
		// Activate parallax
		this.parallax = new Parallax(this.scene);
		
		// Get url parameters
		let query = new URLSearchParams(this.props.location.search);
		
		this.setState({
			downloadLink: query.get('download'),
			status: query.get('status')
		});
		
		// Return to summary if status is cancel
		if (query.get('status') === 'cancel') {
			this.props.history.push('summary')
		}
		
		setTimeout(
			function () {
				this.backToHome()
			}
				.bind(this),
			60000
		);
	}
	
	componentWillUnmount() {
		this.parallax.disable();
	}
	
	backToHome = () => {
		// If registered User
		if (this.state.user.group === '0') {
			this.props.history.push('/' + this.props.language + '/profile')
		}
		
		// If Admin
		else if (this.state.user.group === '1') {
			this.props.history.push('/' + this.props.language + '/code-validation')
		}
		
		// If not registered user
		else {
			this.props.history.push('/' + this.props.language)
		}
	}
	
	handleCheck() {
		this.setState({
			isActive: !this.state.isActive
		});
	}
	
	downloadTicket() {
		console.log('downloadticket', this.state.status, this.state.downloadLink);
		if (this.state.status === 'success') {
			window.open(this.state.downloadLink, "_self")
		}
	}
	
	downloadToPrintTicket(file) {
		console.log(file);
		print(file);
	}
	
	clearSelectedData() {
		localStorage.removeItem('timer');
		localStorage.removeItem('price');
		localStorage.removeItem('priceId');
		localStorage.removeItem('discount-price');
		localStorage.removeItem('ticket-title');
		localStorage.removeItem('ticket-type');
		localStorage.removeItem('pool-title');
		localStorage.removeItem('product-id');
		localStorage.removeItem('selected-count');
		localStorage.removeItem('selected-count-title');
		localStorage.removeItem('selected-date');
		localStorage.removeItem('selected-time');
		localStorage.removeItem('selected-email');
		localStorage.removeItem('sessionID');
		localStorage.removeItem('reserved-tickets');
		localStorage.removeItem('files');
	}
	
	clearLocalstorage() {
		
		// Clear localstorage
		if (!this.state.user) {
			localStorage.clear();
			this.props.history.push('/' + this.props.language + '/book-visit');
		}
		
		if (this.state.user) {
			if (this.state.user.group === '0') {
				this.clearSelectedData();
				this.props.history.push('profile')
			}
			
			if (this.state.user.group === '1') {
				this.clearSelectedData();
				this.props.history.push('code-validation')
			}
		}
	}
	
	render() {
		const translate = this.props.translate;
		
		return (
			<div>
				<Helmet>
					<title>{translate.rules_title}</title>
				</Helmet>
				
				<div className="container-fluid summary">
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					{/* If admin */}
					{this.state.user.group === '1' && (
						<div className="confirmation__content single">
							<h1>{translate.confirmation_title}</h1>
							<p className="description">{this.state.selectedBookTypeTitle} {translate.confirmation_subtitle}</p>
							
							{/*}
							 <p className="seperator_or">{translate.confirmation_or}</p>
							 
							 <p className="btn-red btn" onClick={this.downloadTicket}>{translate.confirmation_download_ticket}</p>
							 */}
							
							
							{this.state.files.map((file, index) =>
								<p key={index}
									 className="btn-red btn"
									 onClick={e => this.downloadToPrintTicket(file)}>
									{translate.confirmation_print} {index + 1}
								</p>
							)}
							
							
							<p className="home-link" onClick={this.clearLocalstorage}>{translate.confirmation_back_to_home}</p>
						</div>
					)}
					
					{/* Ticket bought without registration*/}
					{!this.state.user && (
						<div className="confirmation__content single">
							<h1>{translate.confirmation_title}</h1>
							<p className="description">{translate.confirmation_ticket_sent_to_email}</p>
							
							<p className="seperator_or">{translate.confirmation_or}</p>
							
							<p className="btn-red btn" onClick={this.downloadTicket}>{translate.confirmation_download_ticket}</p>
							
							<p className="home-link" onClick={this.clearLocalstorage}>{translate.confirmation_back_to_home}</p>
						</div>
					)}
					
					{/* Ticket bought with registration */}
					{this.state.user.group === '0' && (
						<div className="confirmation__content registred">
							<h1>{translate.confirmation_title}</h1>
							
							{this.state.selectedTicketType === 'family' && (
								<div>
									<p className="description">{translate.confirmation_ticket_sent_to_email}</p>
									<p className="btn-red btn" onClick={this.clearLocalstorage}>{translate.confirmation_reserve_visit}</p>
								</div>
							)}
							
							{this.state.selectedTicketType !== 'family' && (
								<div>
									<p className="description">{this.state.selectedBookTypeTitle} {translate.confirmation_subtitle}</p>
									<p className="btn-red btn" onClick={this.clearLocalstorage}>{translate.confirmation_reserve_visit}</p>
								</div>
							)}
							
							{this.state.downloadLink && (
								<p className="home-link" onClick={this.downloadTicket}>{translate.confirmation_download_ticket}</p>
							)}
						</div>
					)}
				
				</div>
			</div>
		);
	}
}

export default Confirmation;
