import React from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from 'react-helmet';
import Parallax from 'parallax-js';

import './Forms.css';
import bgImg from "../../assets/img/book-visit.svg";

class RegistrationValidation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			hidden: true,
			email: null,
			password: null,
		};
		
		this.handleCheck = this.handleCheck.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		
	}
	
	componentDidMount() {
		this.parallax = new Parallax(this.scene)
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	
	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}
	
	handleCheck() {
		// let input = {...this.state};
	}
	
	render() {
		
		const translate = this.props.translate
		
		return (
			<div>
				<Helmet>
					<title>{translate.registration_validation_title}</title>
				</Helmet>
				
				<div className="container-fluid validation">
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					<div className="email-verification">
						
						<h1>{translate.form_validation_title}</h1>
						
						<p>{translate.form_validation_intro}</p>
						
						<p>
							<span className="info">!</span>{translate.form_validation_didnt_get_letter} <span>{translate.form_validation_send_again}</span>
						</p>
						
						<NavLink className="btn" to={'/'}>{translate.form_validation_understand	}</NavLink>
					
					</div>
				</div>
			
			</div>
		);
	}
}

export default RegistrationValidation;
