import React from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import Select from 'react-select';

// Components
import Sidebar from "./Sidebar";
// Style
import './Book.css';

class SelectCount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: props.isActive || false,
			selectedOption: null,
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedPoolType: localStorage.getItem('pool-title'),
			selectedProductID: localStorage.getItem('product-id'),
			selectedPriceID: localStorage.getItem('priceId'),
			selectedCount: localStorage.getItem('selected-count'),
			selectedBookType: localStorage.getItem('ticket-type'),
			selectedEmail: localStorage.getItem('selected-email'),
			sessionID: localStorage.getItem('sessionID'),
			loggedIn: localStorage.getItem('loggedIn'),
			user: JSON.parse(localStorage.getItem('user')) || false,
			title: '',
			subtitle: '',
			translate: '',
			data: [],
			prices: []
		};

		this.routeChange = this.routeChange.bind(this);
		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {
		axios.get(window.DEV_API + "api/prices/")
			.then(response => {
				if (!response.data.error) {
					this.setState({
						prices: response.data.prices
					});

				} else {
					alert(response.data.error)
				}
			}).catch(error => console.log(error));


		// Return to home view if previous col
		if (!this.state.selectedBookType) {
			this.props.history.push('/' + this.props.language +'/book-visit')
		}

		// Find product data
		axios.get(window.DEV_API + "api/products/")
			.then(response => {

				if (!response.data.error) {

					// Find product title / id
					if (this.state.user.group === '0' || this.state.selectedBookType === 'spa' || this.state.selectedBookType === 'family') {

						const product = response.data.products.find((item) => item.id === this.state.selectedProductID);

						this.setState({
							selectedPoolType: product.title,
							selectedProductID: product.id
						});

						localStorage.setItem('pool-title', product.title)
					}

					// Show right header / subtitle
					if (this.state.selectedBookType === 'subscription') {
						this.setState({
							title: response.data.translate.choose_count_subscription_title,
							subtitle: response.data.translate.choose_count_subscription_intro
						})
					} else {
						this.setState({
							title: response.data.translate.choose_count_title,
							subtitle: response.data.translate.choose_count_intro
						})
					}
				} else {
					alert(response.data.error)
				}
			}).catch(error => console.log(error));
	}

	handleChange = (selectedOption) => {
		this.setState({
			selectedOption
		});

		localStorage.setItem('selected-count', selectedOption.value);
		localStorage.setItem('selected-count-title', selectedOption.label);


		if (selectedOption.value) {
			this.setState({
				isActive: true
			});

			let self = this;

			// If selected book type is subscription
			let quantity = this.state.selectedBookType === "subscription" ? (selectedOption.value).toString() : "1";

			axios.get(window.DEV_API + "api/prices/")
				.then(response => {
					if (!response.data.error) {

						const price = response.data.prices.find((item) =>
							item.type === self.state.selectedBookType &
							item.product_id === parseInt(self.state.selectedProductID) &
							item.quantity === quantity);

						this.setState({
							selectedPriceID: price.id
						});

						localStorage.setItem('price', price.price);
						localStorage.setItem('priceId', price.id);
						localStorage.setItem('discount-price', price.discount);
					} else {
						alert(response.data.error)
					}
				}).catch(error => console.log(error));
		}
	};

	handleOptions = () => {
		let translate = this.props.translate;
		if (this.state.selectedBookType === "single" || this.state.selectedBookType === "spa") {
			return [
				{value: 1, label: translate.choose_count_alone},
				{value: 2, label: '2 ' + translate.choose_count_visitors},
				{value: 3, label: '3 ' + translate.choose_count_visitors},
				{value: 4, label: '4 ' + translate.choose_count_visitors},
				{value: 5, label: '5 ' + translate.choose_count_visitors}
			];
		}

		if (this.state.selectedBookType === "family") {
			return [
				{value: 3, label: 'Ģimenēm 1+2 vai 2+1'},
				{value: 4, label: 'Ģimenēm 2+2 pers. vai vairāk'},
			];
		}

		if (this.state.selectedBookType === "subscription") {

			let subOptions = [];

			if (!this.state.user.group || this.state.user.group !== '1') {
				this.state.prices.filter(
					item => item.type === this.state.selectedBookType &
						item.product_id === parseInt(this.state.selectedProductID) &
						item.hidden === 0).map((item, index) => {

						subOptions.push({
							value: item.quantity,
							label: item.title + ' ' + item.quantity + ' ' + translate.choose_count_times
						});
					}
				);
			} else {
				this.state.prices.filter(
					item => item.type === this.state.selectedBookType &
						item.product_id === parseInt(this.state.selectedProductID)).map((item, index) => {

						subOptions.push({
							value: item.quantity,
							label: item.title + ' ' + item.quantity + ' ' + translate.choose_count_times
						});
					}
				);
			}

			return subOptions
		}
	}

	routeChange() {
		// Start reservation session if ticket type is subscription
		if(this.state.selectedPriceID){
			if (this.state.selectedBookType === "subscription" || this.state.selectedBookType === "spa") {
				
				let self = this;
				
				axios.post(window.DEV_API + 'api/reservation/start', {
					session_id: this.state.sessionID,
					subscription_id: localStorage.getItem('subscription_id'),
					product_id: this.state.selectedProductID,
					price_id: this.state.selectedPriceID,
					date_time: '',
					count: this.state.selectedBookType === "spa" ? localStorage.getItem('selected-count') : '1'
				}).then(function (response) {
					
					// Save received tickets to localstorage
					localStorage.setItem('reserved-tickets', JSON.stringify(response.data.tickets))
					
					// Save session ID if session doesn't exist
					if (!self.state.sessionID) {
						localStorage.setItem('sessionID', response.data.session)
					}
					
					// Check if session is still active
					if (response.data.error) {
						if (response.data.error === 'session') {
							alert("Sesija ir beigusies")
						}
						
						//	If all good - continue to next step
					} else {
						
						// Start session timer
						localStorage.setItem('timer', Date.now())
						
						if (self.state.user.group === "0" && self.state.selectedBookType === 'subscription') {
							self.props.history.push('summary');
							
						} else if(self.state.selectedEmail || localStorage.getItem('subscription_id')){
							self.props.history.push('summary');
						} else {
							self.props.history.push('choose-checkout');
						}
					}
				}).catch(error => {console.log(error)})
			} else {
				this.props.history.push('choose-date');
			}
		} else{
			alert('Something went wrong')
		}
	}

	handleLink() {
		if (this.state.user.group === "0" && this.state.selectedBookType !== 'family') {
			this.props.history.push('/' + this.props.language + '/profile');

		} else if (this.state.user.group === "1" && this.state.selectedBookType === 'family') {
			this.props.history.push('code-validation');

		} else if (this.state.selectedBookType === 'family' || this.state.selectedBookType === 'spa') {
			this.props.history.push('/' + this.props.language +'/book-visit');

		} else {
			this.props.history.push('choose-swimming-pool');
		}

		window.removeEventListener('popstate', this.handleLink)
	}

	render() {

		const {selectedOption} = this.state;
		const translate = this.props.translate;

		const choise = [
			{name: this.state.selectedBookTypeTitle},
			{name: this.state.selectedPoolType}
		]

		return (
			<div>
				<Helmet>
					<title>{translate.choose_count_title}</title>
				</Helmet>

				<div className="container-fluid">
					<div className="row">

						<div className="sidebar-wrap col-lg-5 col-md-12">
							<div className="sidebar-arrow">
								<p className="back-arrow"
									 onClick={e => this.handleLink()}>
								</p>
							</div>

							<Sidebar
								bgHeight={40}
								parentProps={this.props}
								translate={this.props.translate}
								items={choise}/>
						</div>

						<div className="content col-lg-7 col-md-12">
							<div className="row active-step">
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3"></div>
								<div className="col-sm-3"></div>
								<div className="col-sm-3"></div>
							</div>

							<div className="header">
								<div className="row">
									<h2>{this.state.title}</h2>
									<p>{this.state.subtitle}</p>
								</div>
							</div>

							<div className="select-count row">

								<div className="select-wrap">
									<Select
										className="react-select"
										classNamePrefix="react-select"
										value={selectedOption}
										placeholder={translate.choose_count_choose}
										onChange={this.handleChange}
										options={this.handleOptions()}
										theme={(theme) => ({
											...theme,
											colors: {
												...theme.colors,
												primary25: '#175aa8',
												primary: '#175aa8',
											},
										})}
									/>

								</div>
								<button
									className='btn'
									disabled={!this.state.isActive}
									onClick={this.routeChange}>
									{translate.choose_count_confirm}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SelectCount;
