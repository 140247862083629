import React from 'react';
import axios from 'axios'
import {ReactComponent as Password} from '../../../assets/img/password.svg';


class TabPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			successMsg: false,
			
			hiddenOld: true,
			hidden: true,
			hiddenAgain: true,
			
			passwordOld: '',
			password: '',
			passwordAgain: '',
			
			passwordOldError: false,
			passwordError: false,
			passwordAgainError: false,
			
			passwordOldValid: false,
			passwordValid: false,
			passwordAgainValid: false,
			
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleRules = this.handleRules.bind(this);
		this.toggleOldShow = this.toggleOldShow.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.toggleAgainShow = this.toggleAgainShow.bind(this);
	}
	
	componentDidMount() {
		const user = JSON.parse(localStorage.getItem('user'));
		
		this.setState({
			token: user.token
		});
	}
	
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
		
		this.validateField(name, event.target.value)
	}
	
	handleRules(event) {
		this.setState({
			acceptRulesValid: event.target.checked
		})
	}
	
	validateField(fieldName, value) {
		
		let passwordOldValid = this.state.passwordOldValid;
		let passwordValid = this.state.passwordValid;
		let passwordAgainValid = this.state.passwordAgainValid;
		
		switch (fieldName) {
			
			case "passwordOld":
				passwordOldValid = value.length >= 8;
				break;
			case "password":
				passwordValid = !!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);
				break;
			case "passwordAgain":
				passwordAgainValid = !!value.match(this.state.password);
				break;
			default:
				break;
		}
		
		this.setState({
			passwordOldValid: passwordOldValid,
			passwordValid: passwordValid,
			passwordAgainValid: passwordAgainValid,
		}, this.validateForm)
	}
	
	validateForm() {
		let form = this.state;
		
		this.setState({
			formValid: form.passwordOldValid && form.passwordAgainValid
		})
	}
	
	handleSubmit = event => {
		event.preventDefault();
		
		if (!this.state.formValid) {
			this.setState({
				passwordOldError: !this.state.passwordOldValid,
				passwordError: !this.state.passwordValid,
				passwordAgainError: !this.state.passwordAgainValid,
			})
		} else {
			
			
			let self = this;
			axios.post(window.DEV_API + 'api/user/password', {
					old_password: this.state.passwordOld,
					new_password: this.state.passwordAgain
				}, {
					headers: {'Authorization': this.state.token}
				})
				
				.then(function (response) {
					if (response.data.errors) {
						if (response.data.errors.password) {
							self.setState({
								passwordOldError: true,
							})
						} else {
							self.setState({
								passwordOldError: false,
							})
						}
					} else {
						self.setState({
							successMsg: true
						})
					}
				})
				
				.catch(error => {
					console.log(error)
				})
			
			
			this.setState({
				passwordOldError: false,
				passwordError: false,
				passwordAgainError: false,
			})
		}
		
	};
	
	toggleOldShow() {
		this.setState({hiddenOld: !this.state.hiddenOld});
	}
	
	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}
	
	toggleAgainShow() {
		this.setState({hiddenAgain: !this.state.hiddenAgain});
	}
	
	
	render() {
		
		const formErrors = this.state;
		const translate = this.props.translate;
		
		return (
			<form onSubmit={this.handleSubmit} noValidate>
				
				<div className="inputs">
					
					<div className={formErrors.passwordOldError ? "form-group error" : "form-group"}>
						<input className="form-control password"
									 type={this.state.hiddenOld ? "password" : "text"}
									 id="password-old"
									 value={this.state.passwordOld}
									 onChange={this.handleChange('passwordOld')}
									 required/>
						
						<label className="form-control-placeholder"
									 htmlFor="password">{translate.form_old_password}
						</label>
						
						<div className="icon">
							<Password onClick={this.toggleOldShow}/>
						</div>
						
						{formErrors.passwordOldError && (
							<span className="error-msg">{translate.form_old_password_error}</span>
						)}
					</div>
					
					<div className={formErrors.passwordError ? "form-group error" : "form-group"}>
						<input className="form-control password"
									 type={this.state.hidden ? "password" : "text"}
									 id="password"
									 value={this.state.password}
									 onChange={this.handleChange('password')}
									 required/>
						
						<label className="form-control-placeholder"
									 htmlFor="password">{translate.form_password}
						</label>
						
						<div className="icon">
							<Password onClick={this.toggleShow}/>
						</div>
						
						{formErrors.passwordError && (
							<span className="error-msg">{translate.form_error_password}</span>
						)}
					</div>
					
					<div
						className={formErrors.passwordAgainError ? "form-group error" : "form-group"}>
						<input className="form-control password"
									 type={this.state.hiddenAgain ? "password" : "text"}
									 id="passwordAgain"
									 value={this.state.passwordAgain}
									 onChange={this.handleChange('passwordAgain')}
									 required/>
						<label className="form-control-placeholder"
									 htmlFor="passwordAgain">{translate.form_password_again}
						</label>
						
						<div className="icon">
							<Password onClick={this.toggleAgainShow}/>
						</div>
						
						{formErrors.passwordAgainError && (
							<span className="error-msg">{translate.form_error_password_again}</span>
						)}
						
						{formErrors.successMsg && (
							<span className="success-msg">{translate.form_password_success_msg}</span>
						)}
					</div>
				
				</div>
				
				<div className="btn-wrap">
					<button className="btn form-button">
						{translate.common_save}
					</button>
				</div>
			</form>
		
		);
	}
}

export default TabPassword;
