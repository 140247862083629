import React from 'react';
import './../Profile.css';

import TabProfile from './Tab-profile'
import TabPassword from './Tab-password'

class PersonalData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showProfile: true,
			showPassword: false
		};
		
		this.showProfileTab = this.showProfileTab.bind(this);
		this.showPasswordTab = this.showPasswordTab.bind(this);
	}
	
	showProfileTab() {
		this.setState({
			showProfile: true,
			showPassword: false
		})
	}
	
	showPasswordTab() {
		this.setState({
			showProfile: false,
			showPassword: true
		})
	}
	
	render() {
		const translate = this.props.translate;
		
		return (
			
			<div className="profile__body">
				
				<h2>{translate.profile_body_personal_data}</h2>
				
				<div className="tabs__wrap">
					
					<div className="tabs__navigation">
						
						<p className={this.state.showProfile ? 'active': ''}
							 onClick={this.showProfileTab}>
							{translate.profile_body_profile}
						</p>
						
						<p className={this.state.showPassword ? 'active': ''}
							 onClick={this.showPasswordTab} >
							{translate.profile_body_change_password}
						</p>
					
					</div>
					
					
					<div className="tabs__content">
						
						{/* Tab Profile information */}
						{this.state.showProfile && (
							<div className="tab__profile">
								<TabProfile translate={this.props.translate}/>
							</div>
						)}
						
						{/* Tab change password */}
						{this.state.showPassword && (
							<div className="tab__password">
								<TabPassword translate={this.props.translate}/>
							</div>
						)}
						
					</div>
				</div>
			
			
			</div>
		);
	}
}


export default PersonalData;
