import React from 'react';
import ticketOk from "../../../assets/img/ticket-ok.svg";
import axios from "axios";
import checkGreen from "../../../assets/img/check-green.svg";
import moment from "moment";

class ResultHasSbscriptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: JSON.parse(localStorage.getItem('user')),
			voidChoice: 'all',
			voidPoolChoice: '',
			showVoidModal: false,
			
			registerChoice: '1',
			showRegisterModal: false,
			
			reserveChoice: '1',
			showReserveModal: false,
			
			purchaseChoice: '1',
			subscription_id: '',
			
			showResult: true,
			action: '',
			showThnx: false,
			dateTime: moment().format("YYYY-MM-DD HH:00:00"),
		};
		
		// Void subscriptions
		this.handleVoidSelectChange = this.handleVoidSelectChange.bind(this);
		this.handleSubscriptionVoid = this.handleSubscriptionVoid.bind(this);
		this.handleVoidModal = this.handleVoidModal.bind(this);
		
		// Register subscription large / small pool
		this.handleRegisterChange = this.handleRegisterChange.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.handleRegisterModal = this.handleRegisterModal.bind(this);
		
		//  Reserve data / time for small or large pool
		this.handleReserveChange = this.handleReserveChange.bind(this);
		this.handleReserve = this.handleReserve.bind(this);
		this.handleReserveModal = this.handleReserveModal.bind(this);
		
		//  Purchase subscription for small or large pool
		this.handlePurchaseChange = this.handlePurchaseChange.bind(this);
		this.handlePurchase = this.handlePurchase.bind(this);
	}
	
	componentDidMount() {
		if(this.props.ticket.subscriptions[0].available === 0){
			this.setState({
				reserveChoice: '2',
				registerChoice: '2'
			})
		}
		
		if(this.props.ticket.subscriptions[0]){
			this.setState({
				subscription_id: this.props.ticket.subscriptions[0].id
			})
		}
		
		if(this.props.ticket.subscriptions[1]){
			this.setState({
				subscription_id: this.props.ticket.subscriptions[1].id
			})
		}
	}
	
	// Void subscriptions
	handleVoidSelectChange(e) {
		this.setState({
			voidChoice: e.target.value
		});
	}
	
	handleSubscriptionVoid(e) {
		e.preventDefault();
		
		axios.post(window.DEV_API + 'api/admin/subscription', {
			product_id: this.state.voidPoolChoice,
			number: this.props.code,
			type: this.state.voidChoice
		}, {
			headers: {'Authorization': this.state.user.token}
		}).then(response => {
			this.props.hideResultsContent();
			
			this.setState({
				showResult: false,
				showVoidModal: false,
				showThnx: true
			})
			
		}).catch(error => console.log(error))
		
	}
	
	handleVoidModal(productId) {
		this.setState({
			showVoidModal: !this.state.showVoidModal,
			voidPoolChoice: productId,
			action: 'void'
		})
	}
	
	// Register subscription large / small pool
	handleRegisterChange(e) {
		this.setState({
			registerChoice: e.target.value
		});
	}
	
	handleRegister() {
		// Register Visit
		axios.post(window.DEV_API + 'api/admin/register', {
			subscription_id: this.props.code,
			product_id: this.state.registerChoice,
			date_time: this.state.dateTime,
		}, {
			headers: {'Authorization': this.state.user.token}
		}).then(response => {
			
			if (!response.data.error) {
				this.props.hideResultsContent();
				
				this.setState({
					showResult: false,
					showRegisterModal: false,
					showThnx: true,
					action: 'register'
				})
			} else {
				alert("error " + response.data.error)
			}
		}).catch(error => console.log(error))
	}
	
	handleRegisterModal() {
		this.setState({
			showRegisterModal: !this.state.showRegisterModal
		})
	}
	
	// Reserve data / time for small or large pool
	handleReserveChange(e) {
		this.setState({
			reserveChoice: e.target.value
		});
	}
	
	handleReserve() {
		this.props.selectDate('subscription');
		
		localStorage.setItem('ticket-type', 'single');
		localStorage.setItem('product-id', this.state.reserveChoice);
		
	}
	
	handleReserveModal() {
		this.setState({
			showReserveModal: !this.state.showReserveModal
		})
	}
	
	// Add subscriptions
	handlePurchaseChange(e) {
		this.setState({
			purchaseChoice: e.target.value
		});
	}
	
	handlePurchase(e) {
		
		e.preventDefault();
		
		// Get user Email address to use it in checkout
		if(this.props.ticket.email){
			localStorage.setItem('selected-email', this.props.ticket.email);
		}
		localStorage.setItem('subscription_id', this.state.subscription_id);
		localStorage.setItem('ticket-title', this.props.translate.choose_product_subscription_title);
		localStorage.setItem('ticket-type', 'subscription');
		this.props.history.push('choose-swimming-pool');
	}
	
	
	render() {
		const translate = this.props.translate;
		const ticket = this.props.ticket;
		
		return (
			<div>
				
				{/* Subscription has results*/}
				{this.state.showResult && (
					<div>
						<div className="result result__ok">
							<div className="header">
								<p>{translate.admin_content_subscription_ok}</p>
								<img src={ticketOk} alt={translate.admin_content_result}/>
							</div>
							
							{/* Show large pool subscription data if available*/}
							{ticket.subscriptions[0].available > 0 && (
								<div className="item featured">
									<div><p>{ticket.subscriptions[0].product_title}</p></div>
									<div>
										<p>{ticket.subscriptions[0].available} {ticket.subscriptions[0].available === 1
											? translate.admin_content_ticket_count_singular
											: translate.admin_content_ticket_count_plural}
										</p>
										<b>/</b>
										<p onClick={e => this.handleVoidModal(1)}>
											{translate.common_void}
										</p>
									</div>
								</div>
							)}
							
							{/* Show small pool subscription data if available*/}
							{ticket.subscriptions[1].available > 0 && (
								<div className="item">
									<div><p>{ticket.subscriptions[1].product_title}</p></div>
									<div>
										<p>{ticket.subscriptions[1].available} {ticket.subscriptions[1].available === 1
											? translate.admin_content_ticket_count_singular
											: translate.admin_content_ticket_count_plural}
										</p>
										<b>/</b>
										<p onClick={e => this.handleVoidModal(2)}>
											{translate.common_void}
										</p>
									</div>
								</div>
							)}
							
							{/* Show name */}
							{ticket.name && (
								<div className="item">
									<div><p>{translate.form_name}</p></div>
									<div><p>{ticket.name}</p></div>
								</div>
							)}
							
							{/* Show email */}
							{ticket.email && (
								<div className="item">
									<div><p>{translate.form_email}</p></div>
									<div><p>{ticket.email}</p></div>
								</div>
							)}
							
							<div className="item">
								<div>
									{/* if discount than show span text */}
									{ticket.subscriptions[0].discount > '0' && (
										<span>( {translate.admin_content_discount_text} )</span>
									)}
									<p>{translate.admin_content_discount}:</p>
								</div>
								<div>
									<p>
										{
											ticket.subscriptions[0].discount > '0'
												? translate.common_yes
												: translate.admin_content_without_discount
										}
									</p>
								</div>
							</div>
							
							{/* Show if Municipal */}
							{ticket.municipal === '1' && (
								<div className="item">
									<div><p>{translate.form_municipal}</p></div>
									<div><p>{translate.common_yes}</p></div>
								</div>
							)}
						</div>
						
						<div className="result__ok btns">
							<button
								onClick={this.handleRegisterModal}
								className="btn-red btn">
								{translate.common_register}
							</button>
							
							<button onClick={this.handlePurchase}
											className="btn-red btn">
								{translate.admin_content_fill_subscription}
							</button>
							
							<button
								onClick={this.handleReserveModal}
								className="btn-transparent">
								{translate.common_reserve}
							</button>
						</div>
					</div>
				)}
				
				{/* Void modal*/}
				{this.state.showVoidModal && (
					<div className="modal__void">
						
						<div className="modal__content">
							<h4>{translate.admin_content_void_subscriptions}</h4>
							
							<form onSubmit={this.handleSubscriptionVoid}>
								
								<ul className="radio_btns">
									<li>
										<label>
											<input
												type="radio"
												value="all"
												checked={this.state.voidChoice === "all"}
												onChange={this.handleVoidSelectChange}
											/>
											{translate.admin_content_void_all}
											<span></span>
										</label>
									</li>
									
									<li>
										<label>
											<input
												type="radio"
												value="one"
												checked={this.state.voidChoice === "one"}
												onChange={this.handleVoidSelectChange}
											/>
											{translate.admin_content_void_one}
											<span></span>
										</label>
									</li>
								</ul>
								
								<div className="btn-wrap">
									<button className="btn">
										{translate.common_yes}
									</button>
									
									<button type="button" className="btn-transparent"
													onClick={this.handleVoidModal}>
										{translate.common_no}
									</button>
								</div>
							</form>
						
						</div>
					
					</div>
				)}
				
				{/* Register Modal*/}
				{this.state.showRegisterModal && (
					<div className="modal__void">
						<div className="modal__content">
							<h4>{translate.admin_content_register_ticket}</h4>
							<p>{translate.admin_content_register_ticket_text}</p>
							
							<form onSubmit={this.handleSubscriptionVoid}>
								{/* ticket.subscriptions[0].available > 0 && ticket.subscriptions[1].available */}
								{(true == true) && (
									<ul className="radio_btns">
										<li>
											<label>
												<input
													type="radio"
													value="1"
													checked={this.state.registerChoice === "1"}
													onChange={this.handleRegisterChange}
												/>
												{translate.admin_content_large_pool}
												<span></span>
											</label>
										</li>
										
										<li>
											<label>
												<input
													type="radio"
													value="2"
													checked={this.state.registerChoice === "2"}
													onChange={this.handleRegisterChange}
												/>
												{translate.admin_content_small_pool}
												<span></span>
											</label>
										</li>
									</ul>
								)}
								
								<div className="btn-wrap">
									<button type="button" className="btn"
													onClick={this.handleRegister}>
										{translate.common_yes}
									</button>
									
									<button type="button" className="btn-transparent"
													onClick={this.handleRegisterModal}>
										{translate.common_no}
									</button>
								</div>
							</form>
						
						
						</div>
					</div>
				)}
				
				{this.state.showReserveModal && (
					<div className="modal__void">
						
						<div className="modal__content">
							<h4>{translate.admin_reserve_modal_title}</h4>
							<p>{translate.admin_reserve_modal_subtitle}</p>
							
							<form onSubmit={this.handleReserve}>
								{/* ticket.subscriptions[0].available > 0 && ticket.subscriptions[1].available > 0 */}
								{(true == true) && (
									<ul className="radio_btns">
										<li>
											<label>
												<input
													type="radio"
													value="1"
													checked={this.state.reserveChoice === "1"}
													onChange={this.handleReserveChange}
												/>
												{translate.admin_content_large_pool}
												<span></span>
											</label>
										</li>
										
										<li>
											<label>
												<input
													type="radio"
													value="2"
													checked={this.state.reserveChoice === "2"}
													onChange={this.handleReserveChange}
												/>
												{translate.admin_content_small_pool}
												<span></span>
											</label>
										</li>
									</ul>
								)}
								
								<div className="btn-wrap">
									<button className="btn">
										{translate.common_yes}
									</button>
									
									<button type="button" className="btn-transparent"
													onClick={this.handleReserveModal}>
										{translate.common_no}
									</button>
								</div>
							</form>
						
						</div>
					
					</div>
				)}
				
				{/* Result THNX */}
				{this.state.showThnx && (
					<div>
						<h2>{translate.common_thnx}</h2>
						
						<div className="result result__thnx">
							
							<p>
								<img src={checkGreen} alt=""/>
								{this.state.action === 'register' && (
									translate.admin_content_subscription_registred
								)}
								
								{this.state.action === 'void' && (
									translate.admin_content_subscription_voided
								)}
							
							</p>
							
							<button
								onClick={() => this.props.showCodeValidation('codeValidation')}
								className="btn-transparent" type="button">
								{translate.common_go_back_to_home}
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ResultHasSbscriptions;
