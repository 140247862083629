import React from 'react';
import { NavLink } from "react-router-dom";
import './Buy.css';

class Buy extends React.Component {
  render () {
    
    const translate = this.props.translate;
    
    return (
      <div className="buy">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <h4>{translate.action_block_title}</h4>
              <p>{translate.action_block_intro}</p>
              <NavLink className="btn" to="/">{translate.action_block_button}</NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Buy;
