import React from 'react';
import {Helmet} from "react-helmet";

// Style
import './Book.css';
// Assets
import rule1 from "../../assets/img/rules-1.svg";
import rule2 from "../../assets/img/rules-2.svg";
import rule3 from "../../assets/img/rules-3.png";
import rule4 from "../../assets/img/rules-4.svg";
// Components
import Sidebar from "./Sidebar";

class Rules extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isActive: props.isActive || false,
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedPoolType: localStorage.getItem('pool-title'),
			selectedDate: localStorage.getItem('selected-date'),
			selectedCount: localStorage.getItem('selected-count'),
			selectedCountTitle: localStorage.getItem('selected-count-title'),
			selectedTime: localStorage.getItem('selected-time'),
			user: JSON.parse(localStorage.getItem('user')) || false,
		};
		this.handleCheck = this.handleCheck.bind(this);
		this.routeChange = this.routeChange.bind(this);
		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {
		if (!this.state.selectedBookTypeTitle || !this.state.selectedPoolType || !this.state.selectedCount || !this.state.selectedTime) {
			this.props.history.push('/' + this.props.match.params.lang + '/book-visit');
		}

		// window.addEventListener('popstate', this.handleLink);
	}

	handleCheck() {
		this.setState({
			isActive: !this.state.isActive
		});
	}

	handleLink() {
		this.props.history.push('choose-date');

		// window.removeEventListener('popstate', this.handleLink);

	}

	routeChange() {
		if (this.state.user.group === '0') {
			this.props.history.push('summary')
		} else {
			this.props.history.push('choose-checkout');
		}
	}

	render() {
		const translate = this.props.translate;
		const choise = [
			{
				name: this.state.selectedBookTypeTitle
			}, {
				name: this.state.selectedPoolType
			}, {
				name: this.state.selectedCountTitle
			}, {
				name: this.state.selectedDate + ',' + this.state.selectedTime
			}
		]

		return (<div>
			<Helmet>
				<title>{translate.rules_title}</title>
			</Helmet>

			<div className="container-fluid">
				<div className="row">
					<div className="sidebar-wrap col-lg-5 col-md-12">
						<div className="sidebar-arrow">
							<p className="back-arrow" onClick={e => this.handleLink()}></p>
						</div>

						<Sidebar bgHeight={60} parentProps={this.props} translate={this.props.translate} items={choise}/>
					</div>
					<div className="content col-lg-7 col-md-12">
						<div className="row active-step">
							<div className="col-sm-3 active"></div>
							<div className="col-sm-3 active"></div>
							<div className="col-sm-3 active"></div>
							<div className="col-sm-3 active"></div>
							<div className="col-sm-3 "></div>
						</div>

						<div className="header">
							<div className="row">
								<h2>{translate.rules_title}</h2>
								<p>{translate.rules_intro}</p>
							</div>
						</div>

						<div className="rules row">

							<div className="col-sm-6 rule">
								<img src={rule1} alt="rules"/>
								<div>
									<b>{translate.rules_rule_time_title}</b>
									<p>{translate.rules_rule_time_text}</p>
								</div>
							</div>

							<div className="col-sm-6 rule">
								<img src={rule2} alt="rules"/>
								<div>
									<b>{translate.rules_rule_clothes_title}</b>
									<p>{translate.rules_rule_clothes_text}</p>
								</div>
							</div>

							<div className="col-sm-6 rule">
								<img src={rule3} alt="rules" style={{'width':'110px'}}/>
								<div>
									<b>{translate.rules_rule_shorts_title}</b>
								</div>
							</div>

							<div className="col-sm-6 rule">
								<img src={rule4} alt="rules"/>
								<div>
									<b>{translate.rules_rule_hat_title}</b>
								</div>
							</div>

						</div>

						<div className="accept-rules">

							<label>
								<input type="checkbox" onChange={this.handleCheck} checked={this.state.isActive}/>

								<div></div>

								<p>{translate.rules_accept}</p>
							</label>

							<button className='btn' disabled={!this.state.isActive} onClick={this.routeChange}>
								{translate.rules_confirm}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}

export default Rules;
