import React from 'react';
import ticketEmpty from "../../../assets/img/ticket-empty.svg";

class ResultEmpty extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBookTypeTitle: '',
			subscription_id: '',
		}
	}
	
	componentDidMount() {
		if(this.props.ticket.subscriptions[0]){
			this.setState({
				subscription_id: this.props.ticket.subscriptions[0].id
			})
		}
		
		if(this.props.ticket.subscriptions[1]){
			this.setState({
				subscription_id: this.props.ticket.subscriptions[1].id
			})
		}
	}
	
	handleTicketsPurchase = (type, title) => {
		
		// Get user Email address to use it in checkout
		if(this.props.parentProps.ticketData.email){
			localStorage.setItem('selected-email', this.props.parentProps.ticketData.email);
		}
		localStorage.setItem('subscription_id', this.state.subscription_id);
		localStorage.setItem('ticket-title', title);
		localStorage.setItem('ticket-type', type);
		this.props.parentProps.history.push('choose-swimming-pool');
		
	};
	
	render() {
		
		const translate = this.props.translate;
		
		return (
			<div>
				<div className="result result__empty">
					
					<div className="header">
						
						<p>{translate.admin_content_subscription_empty}</p>
						
						<img src={ticketEmpty} alt={translate.admin_content_subscription_empty}/>
					
					</div>
					
					<div className="item featured">
						<div><p>{translate.profile_body_pool_subscription}</p></div>
					</div>
				</div>
				
				<div className="result__empty btns">
					<button className="btn-red btn"
									onClick={e => this.handleTicketsPurchase(
										'subscription',
										translate.choose_product_subscription_title)}>
						{translate.admin_content_fill_subscription}
					</button>
				</div>
			</div>
		);
	}
}

export default ResultEmpty;
