import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import iconMarker from'../../assets/img/marker.svg';

class Map extends Component {
   render() {
     const mapStyles = [
       {"featureType": "administrative.locality", "elementType": "all", "stylers": [{"hue": "#2c2e33"},{"saturation": 7},{"lightness": 9},{"visibility": "on"}]},
       {"featureType": "landscape", "elementType": "all", "stylers": [{"hue": "#ffffff"},{"saturation": -100},{"lightness": 100},{"visibility": "simplified"}]},
       {"featureType": "poi", "elementType": "all", "stylers": [{"hue": "#ffffff"},{"saturation": -100},{"lightness": 100},{"visibility": "off"}]},
       {"featureType": "road", "elementType": "geometry", "stylers": [{"hue": "#bbc0c4"},{"saturation": -93},{"lightness": 31},{"visibility": "simplified"}]},
       {"featureType": "road", "elementType": "labels", "stylers": [{"hue": "#bbc0c4"},{"saturation": -93},{"lightness": 31},{"visibility": "on"}]},
       {"featureType": "road.arterial", "elementType": "labels", "stylers": [{"hue": "#bbc0c4"},{"saturation": -93},{"lightness": -2},{"visibility": "simplified"}]},
       {"featureType": "road.local", "elementType": "geometry", "stylers": [{"hue": "#e9ebed"},{"saturation": -90},{"lightness": -8},{"visibility": "simplified"}]},
       {"featureType": "transit", "elementType": "all", "stylers": [{"hue": "#e9ebed"},{"saturation": 10},{"lightness": 69},{"visibility": "on"}]},
       {"featureType": "water", "elementType": "all", "stylers": [{"hue": "#e9ebed"},{"saturation": -78},{"lightness": 67},{"visibility": "simplified"}]}
     ];

     const MapCenter = { lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) }

     const GoogleMapExample = withGoogleMap(props => (
        <GoogleMap
          options={{
            styles: mapStyles,
          }}
          defaultCenter = { MapCenter }
          defaultZoom = { 15 }
        >
          <Marker icon={{ url: iconMarker, scaledSize: {width: 30, height: 50} }} position={ MapCenter } />
        </GoogleMap>
     ));

     return(
        <div>
          <div className="map">
            <GoogleMapExample
              containerElement={ <div style={{ height: `520px`, width: '100%' }} /> }
              mapElement={ <div style={{ height: `100%` }} /> }
            />
          </div>
        </div>
   );
   }
};
export default Map;
