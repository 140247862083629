import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import './Menu.css';

import ProfileImg from '../../assets/img/profile.svg';

class Menu extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			cssClass: 'menu',
			cssHidden: '',
			showLoginBtn: false,
			showAdminBtn: false,
			showProfileBtn: false,
			user: '',
			menuLink: '',
			menuLinkName: ''
		};
	}
	
	_getStorageData = async () => {
		const user = JSON.parse(localStorage.getItem('user'));
		
		if (user !== null) {
			
			if (user.group === '1') {
				this.setState({
					showAdminBtn: true
				})
			} else {
				this.setState({
					showProfileBtn: true
				})
			}
			
		} else {
			this.setState({
				showLoginBtn: true,
			})
		}
	}
	
	componentDidMount() {
		this._getStorageData();
		
		// Listen for URL change and set menu CSS class
		this.setMenuClass(this.props.history.location.pathname);
		
		this.props.history.listen(() => {
			this.setMenuClass(this.props.history.location.pathname);
		});
	}
	
	setMenuClass = (pathname) => {
		
		let pathLength = 1;
		let helper = pathname.split('-').shift();
		let bookingName = helper.split('/').pop();
		
		if (pathname !== '/') {
			pathLength = pathname.split(/[?#]/).shift().match(/\/[^/]+?/g).length;
		}
		this.setState({cssClass: pathLength > 1 ? ' menu--inside' : ''})
		this.setState({
			cssHidden:
				
				bookingName === 'choose' ||
				bookingName === 'rules' ||
				bookingName === 'summary' ||
				bookingName === 'register' ||
				bookingName === 'login' ||
				bookingName === 'validation' ||
				bookingName === 'profile' ||
				bookingName === 'forget' ||
				bookingName === 'reset' ||
				bookingName === 'select' ||
				bookingName === 'code' ||
				bookingName === 'confirmation' ||
				bookingName === 'password'
					? 'hidden'
					: 'visible'
		})
	};
	
	render() {
		
		const translate = this.props.translate;
		
		return (
			<div className={this.state.cssHidden}>
				<div className={'mobile_menu' + this.state.cssClass + (this.props.menuopen ? ' open' : '')}>
					<div className={'burger-tap' + this.state.cssClass} role="button">
						<div className={this.props.menuopen ? 'burger open' : 'burger'} onClick={this.props.toggleMenu}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				</div>
				
				<div className={this.props.menuopen ? 'mobile_menu_links open' : 'mobile_menu_links'}>
					<ul>
						<li>
							<NavLink to={'/' + this.props.language} onClick={this.props.toggleMenu}>
								{translate.home}
							</NavLink>
						</li>
						
						{this.props.sections.map(section =>
							<li key={section.id}>
								<NavLink to={section.link} onClick={this.props.toggleMenu}>{section.title}</NavLink>
							</li>
						)}
					</ul>
				</div>
				
				{this.state.cssClass !== '' && (
					<div className="home_link">
						<NavLink to={'/' + this.props.language}></NavLink>
					</div>
				)}
				
				<div className={'menu' + this.state.cssClass}>
					<ul>
						{this.props.sections.map(section =>
							<li key={section.id}><NavLink to={section.link}>{section.title}</NavLink></li>
						)}
					</ul>
				</div>
				
				{this.state.showLoginBtn && (
					<NavLink to={'/' + this.props.language + '/login'} className="profile--link">
						<span>{translate.menu_login}</span>
						<img src={ProfileImg} alt=""/>
					</NavLink>
				)}
				
				{this.state.showProfileBtn && (
					<NavLink to={'/' + this.props.language + '/profile'} className="profile--link">
						<span>{translate.menu_profile}</span>
						<img src={ProfileImg} alt=""/>
					</NavLink>
				)}
				
				{this.state.showAdminBtn && (
					<NavLink to={'/' + this.props.language + '/select-admin'} className="profile--link">
						<span>{translate.common_admin}</span>
						<img src={ProfileImg} alt=""/>
					</NavLink>
				)}
			
			
			</div>
		);
	}
}

export default withRouter(Menu);
