import React from 'react';
import {Helmet} from 'react-helmet';
import Parallax from 'parallax-js';
import axios from "axios";

import './Forms.css';
import bgImg from "../../assets/img/book-visit.svg";

import {ReactComponent as Close} from '../../assets/img/close.svg';
import {ReactComponent as User} from '../../assets/img/user.svg';
import {ReactComponent as Email} from '../../assets/img/email.svg';
import {ReactComponent as Phone} from '../../assets/img/phone.svg';
import {ReactComponent as Password} from '../../assets/img/password.svg';
import {NavLink} from "react-router-dom";


class RegistrationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

			hidden: true,
			hiddenAgain: true,

			name: '',
			email: '',
			phone: '',
			password: '',
			passwordAgain: '',
			acceptRules: true,

			nameError: false,
			emailError: false,
			emailErrorExist: false,
			phoneError: false,
			passwordError: false,
			passwordAgainError: false,
			checkBoxError: false,
			acceptRulesError: false,

			formValid: false,
			nameValid: false,
			emailValid: false,
			phoneValid: true,
			passwordValid: false,
			passwordAgainValid: false,
			acceptRulesValid: false

		};

		this.handleChange = this.handleChange.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.toggleAgainShow = this.toggleAgainShow.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		this.parallax = new Parallax(this.scene)
	}

	componentWillUnmount() {
		this.parallax.disable()
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value)
	}
	
	checkPassword(){
		if(this.state.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)){
			this.setState({
				passwordError: false
			})
		}else{
			this.setState({
				passwordError: true
			})
		}
	}
	
	validateField(fieldName, value) {

		let nameValid = this.state.nameValid;
		let phoneValid = this.state.phoneValid;
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;
		let passwordAgainValid = this.state.passwordAgainValid;
		let acceptRulesValid = this.state.acceptRulesValid;

		switch (fieldName) {
			case "name":
				nameValid = value.length >= 2;
				break;
			case "email":
				emailValid = !!value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
				break;
			case "phone":
				if (value.length > 0) {
					phoneValid = !!value.match(/^\d{8,12}$/);
				} else {
					phoneValid = true;
				}
				break;
			case "password":
				passwordValid = !!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);
				break;
			case "passwordAgain":
				passwordAgainValid = !!value.match(this.state.password)

				break;
			case "rules":
				this.setState({
					acceptRules: !this.state.acceptRules
				});

				acceptRulesValid = this.state.acceptRules;

				break;
			default:
				break;
		}

		this.setState({
			nameValid: nameValid,
			emailValid: emailValid,
			phoneValid: phoneValid,
			passwordValid: passwordValid,
			passwordAgainValid: passwordAgainValid,
			acceptRulesValid: acceptRulesValid
		}, this.validateForm)
	}

	validateForm() {
		let form = this.state;

		this.setState({
			formValid: form.nameValid && form.emailValid && form.phoneValid && form.passwordValid && form.passwordAgainValid && form.acceptRulesValid
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		if (!this.state.formValid) {
			this.setState({
				nameError: !this.state.nameValid,
				emailError: !this.state.emailValid,
				phoneError: !this.state.phoneValid,
				passwordError: !this.state.passwordValid,
				passwordAgainError: !this.state.passwordAgainValid,
				acceptRulesError: !this.state.acceptRulesValid
			})

		} else {
			let self = this;

			axios.post(window.DEV_API + 'api/user/register', {
				name: this.state.name,
				email: this.state.email,
				phone: this.state.phone,
				password: this.state.passwordAgain
			}).then(function (response) {			
					if (response.data.errors) {
						if (response.data.errors.exist) {
							self.setState({
								emailErrorExist: true,
							})
						}
					} else {
						self.props.history.push('login');
					}
				})
				.catch(error => {
					console.log(error)
				})

			this.setState({
				nameError: false,
				emailError: false,
				phoneError: false,
				passwordError: false,
				passwordAgainError: false,
				acceptRulesError: false
			})
		}

	};

	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}

	toggleAgainShow() {
		this.setState({hiddenAgain: !this.state.hiddenAgain});
	}

	goBack() {
		this.props.history.push('/')
	}


	render() {

		const formErrors = this.state;
		const translate = this.props.translate;

		return (
			<div>
				<Helmet>
					<title>{translate.form_btn_register}</title>
				</Helmet>


				<div className="container-fluid form-wrap">

					<h1> {translate.form_title} </h1>

					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>

					<p className="history-back"
						 onClick={this.goBack}>
						{translate.common_close}
						<Close/>
					</p>

					<div className="form-wrap__content">

						<form onSubmit={this.handleSubmit} noValidate>

							<div className="inputs">
								<div className={formErrors.nameError ? "form-group error" : "form-group"}>
									<input className="form-control user"
												 type="text"
												 id="fullname"
												 value={this.state.name}
												 onChange={this.handleChange('name')}
												 required/>

									<label className="form-control-placeholder"
												 htmlFor="fullname">{translate.form_name}</label>

									<div className="icon">
										<User/>
									</div>

									{formErrors.nameError && (
										<span className="error-msg">{translate.form_error_name}</span>
									)}
								</div>

								<div
									className={formErrors.emailError || formErrors.emailErrorExist ? "form-group error" : "form-group"}>
									<input className="form-control email"
												 type="email"
												 id="email"
												 value={this.state.email}
												 onChange={this.handleChange('email')}
												 required/>

									<label className="form-control-placeholder"
												 htmlFor="email">{translate.form_email}</label>

									<div className="icon">
										<Email/>
									</div>

									{formErrors.emailError && (
										<span className="error-msg">{translate.form_error_email}</span>
									)}
									{formErrors.emailErrorExist && (
										<span className="error-msg">{translate.form_error_email_exist}</span>
									)}
								</div>

								<div className={formErrors.phoneError ? "form-group error" : "form-group"}>
									<input className="form-control phone"
												 type="text"
												 id="phone"
												 value={this.state.phone}
												 onChange={this.handleChange('phone')}
												 required/>

									<label className="form-control-placeholder"
												 htmlFor="phone">{translate.form_phone}</label>

									<div className="icon">
										<Phone/>
									</div>

									{formErrors.phoneError && (
										<span className="error-msg">{translate.form_error_phone}</span>
									)}
								</div>

								<div className={formErrors.passwordError ? "form-group error" : "form-group"}>
									<input className="form-control password"
												 type={this.state.hidden ? "password" : "text"}
												 id="password"
												 value={this.state.password}
												 onBlur={this.checkPassword}
												 onChange={this.handleChange('password')}
												 required/>

									<label className="form-control-placeholder"
												 htmlFor="password">{translate.form_password}
									</label>

									<div className="icon">
										<Password onClick={this.toggleShow}/>
									</div>
									
									<span className={'error-msg ' + (formErrors.passwordError ? ' active': ' gray')}>{translate.form_error_password}</span>
									
								</div>

								<div
									className={formErrors.passwordAgainError ? "form-group error" : "form-group"}>
									<input className="form-control password"
												 type={this.state.hiddenAgain ? "password" : "text"}
												 id="passwordAgain"
												 value={this.state.passwordAgain}
												 onChange={this.handleChange('passwordAgain')}
												 required/>
									<label className="form-control-placeholder"
												 htmlFor="passwordAgain">{translate.form_password_again}
									</label>

									<div className="icon">
										<Password onClick={this.toggleAgainShow}/>
									</div>

									{formErrors.passwordAgainError && (
										<span className="error-msg">{translate.form_error_password_again}</span>
									)}
								</div>

								<div className={'accept-rules ' + (this.state.acceptRulesError ? 'error' : '')}>
									<label>
										<input
											type="checkbox"
											name="rules"
											checked={!this.state.acceptRules}
											onChange={this.handleChange('rules')}

										/>

										<div></div>

										<p dangerouslySetInnerHTML={{__html: translate.rules_link}}></p>

									</label>
								</div>
							</div>


							<button
								className="form-button">
								{translate.form_btn_register}
							</button>
						</form>

						<NavLink className='login'
										 to={"login"}>
							{translate.form_link_have_profile}
						</NavLink>
					</div>


				</div>

			</div>
		);
	}
}

export default RegistrationModal;
