import React from "react"
import "./Admin.css"
import moment from 'moment'
import axios from "axios";

class adminSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			largePool: '1',
			smallPoll: '2',
			dateTime: moment().format("YYYY-MM-DD HH:00:00"),
			largePoolCapacity: 0,
			largePoolReservations: 0,
			smallPoolCapacity: 0,
			smallPoolReservations: 0,
		}
	}
	
	componentDidMount() {
		
		let self = this;
		
		// Ge pool capacity, reservations
		axios.get(window.DEV_API + "api/products/")
			.then(response => {
				
				self.setState({
					largePoolCapacity: response.data.products[0].capacity,
					largePoolReservations: response.data.products[0].reservations,
					smallPoolCapacity: response.data.products[1].capacity,
					smallPoolReservations: response.data.products[1].reservations
				})
			}).catch(error => console.log(error));
	}
	
	
	render() {
		const translate = this.props.translate;
		
		return (
			<div className="sidebar-wrap sidebar-wrap__admin">
				<div className="sidebar sidebar__admin ">
					<div className="top">
						<h1>{translate.sidebar_admin_title}</h1>
						
						{!this.props.showInfo && (
							<div className="calendar-links">
								<p onClick={() => this.props.showCalendar("calendar", '1')}>
									{translate.sidebar_admin_large_pool_calendar}
								</p>
								
								<p onClick={() => this.props.showCalendar("calendar", '2')}>
									{translate.sidebar_admin_small_pool_calendar}
								</p>
							</div>
						)}
						
						{this.props.showInfo && (
							<div>
								<span>{translate.admin_content_large_pool}</span>
								<div className="pool">
									<div className="available-visits">
										<h4>{translate.admin_content_free_places}</h4>
										<p className="larger">{this.state.largePoolCapacity - this.state.largePoolReservations}</p>
									</div>
									
									<div className="available-visits">
										<p>{translate.admin_content_reserved_places}</p>
										<p>{this.state.largePoolReservations}</p>
									</div>
								</div>
								
								<span>{translate.admin_content_small_pool}</span>
								<div className="pool">
									<div className="available-visits">
										<h4>{translate.admin_content_free_places}</h4>
										<p className="larger">{this.state.smallPoolCapacity - this.state.smallPoolReservations}</p>
									</div>
									
									<div className="available-visits">
										<p>{translate.admin_content_reserved_places}</p>
										<p>{this.state.smallPoolReservations}</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
	
}

export default adminSidebar
