import React from 'react';
import ticketOk from "../../../assets/img/ticket-ok.svg";
import checkGreen from "../../../assets/img/check-green.svg";
import axios from "axios";

class ResultOkTicket extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmationModal: false,
			showRegisterModal: false,
			showThnx: false,
			showResult: true,
			user: JSON.parse(localStorage.getItem('user')),
			action: ''
		}
		
		this.handleVoid = this.handleVoid.bind(this);
		this.handleRegisterModal = this.handleRegisterModal.bind(this);
		this.handleTicketRegistration = this.handleTicketRegistration.bind(this);
		this.showModal = this.showModal.bind(this);
	}
	
	componentDidMount() {}
	
	handleVoid() {
		
		const user = JSON.parse(localStorage.getItem('user'));
		let self = this
		
		axios.post(window.DEV_API + 'api/admin/cancel', {
			reservation_id: this.props.ticketData.id,
			product_id: this.props.ticketData.product_id,
		}, {
			headers: {'Authorization': user.token}
		}).then(function (response) {
			self.setState({
				showResult: false,
				showThnx: true,
				action: 'void'
			})
		}).catch(error => {
			console.log(error)
		});
	}
	
	handleTicketRegistration() {
		// Confirm Reservation Visit
		if (this.props.ticketData.type === 'reservation') {
			axios.post(window.DEV_API + 'api/admin/confirm', {
				reservation_id: this.props.ticketData.id
			}, {
				headers: {'Authorization': this.state.user.token}
			}).then(response => {
				if (!response.data.error) {
					this.setState({
						showResult: false,
						showThnx: true,
						action: 'register'
					})
				} else {
					alert('Error ' + response.data.error)
				}
			}).catch(error => console.log(error))
		} else {
			// Confirm Ticket Visit
			axios.post(window.DEV_API + 'api/admin/confirm', {
				ticket_id: this.props.ticketData.id
			}, {
				headers: {'Authorization': this.state.user.token}
			}).then(response => {
				if (!response.data.error) {
					this.setState({
						showResult: false,
						showThnx: true,
						action: 'register'
					})
				} else {
					alert('Error ' + response.data.error)
				}
			}).catch(error => console.log(error))
		}
	}
	
	handleRegisterModal() {
		this.setState({
			showRegisterModal: !this.state.showRegisterModal
		})
	}
	
	showModal() {
		this.setState({
			showConfirmationModal: !this.state.showConfirmationModal
		})
	}
	
	render() {
		const translate = this.props.translate;
		const ticket = this.props.ticketData;
		
		return (
			<div>
				{/* Result OK - Ticket */}
				{this.state.showResult && (
					<div className="admin-content">
						
						<h2>{translate.admin_content_result}</h2>
						
						<div className="result result__ok">
							
							<div className="header">
								
								<p>{translate.admin_content_ticket_ok}</p>
								
								<img src={ticketOk} alt={translate.admin_content_result}/>
							
							</div>
							
							{ticket.type === 'reservation' && (
								<div className="item featured">
									<div><p>{ticket.product_title}</p></div>
									<div><p></p></div>
								</div>
							)}
							
							<div className={'item' + (ticket.type !== 'reservation' ? ' featured' : '')}>
								<div>
									<p>
										{(ticket.type === "ticket" || ticket.type === "reservation") && (ticket.price_title)}
										{ticket.type === "group" && (ticket.product_title)}
									</p>
								</div>
								<div></div>
							</div>
							
							{ticket.type === 'reservation' && (
								<div className="item">
									<div>
										<p className="selected-time">
											{ticket.date}
										</p>
									</div>
								</div>
							)}
							
							{ticket.type !== 'reservation' && (
								<div className="item">
									<div>
										<p>
											{ticket.type === "ticket" && (translate.admin_content_bought_date)}
											{ticket.type === "group" && (translate.admin_content_valid_time)}
										</p>
									</div>
									<div><p className="selected-time">{ticket.date}</p></div>
								</div>
							)}
							
							{ticket.type !== 'group' && (
								<div className="item">
									<div>
										{/* if discount than show span text */}
										{ticket.discount > '0' && (
											<span>( {translate.admin_content_discount_text} )</span>
										)}
										<p>{translate.admin_content_discount}:</p>
									</div>
									<div><p>{ticket.discount > '0' ? translate.common_yes : translate.common_no}</p></div>
								</div>
							)}
							
							{ticket.type !== 'group' && (
								<div className="item">
									<div><p>{translate.admin_content_price}:</p></div>
									<div><p className="price">{ticket.total} {translate.common_eur}</p></div>
								</div>
							)}
						</div>
						
						<div className="result__ok btns">
							<button className="btn-red btn"
											onClick={this.handleRegisterModal}>
								{translate.common_register}
							</button>
							{ticket.type !== 'group' && (
								<p onClick={this.showModal}>
									{translate.common_void}
								</p>
							)}
						</div>
						
						{this.state.showConfirmationModal && (
							<div className="modal__void">
								<div className="modal__content">
									<h4>{translate.admin_content_void_ticket}</h4>
									<p>{translate.admin_content_void_ticket_text}</p>
									
									<div className="btn-wrap">
										<button type="button" className="btn"
														onClick={this.handleVoid}>
											{translate.common_yes}
										</button>
										
										<button type="button" className="btn-transparent"
														onClick={this.showModal}>
											{translate.common_no}
										</button>
									</div>
								</div>
							</div>
						)}
						
						{this.state.showRegisterModal && (
							<div className="modal__void">
								<div className="modal__content">
									<h4>{translate.admin_content_register_ticket}</h4>
									<p>{translate.admin_content_register_ticket_text}</p>
									
									<div className="btn-wrap">
										<button type="button" className="btn"
														onClick={this.handleTicketRegistration}>
											{translate.common_yes}
										</button>
										
										<button type="button" className="btn-transparent"
														onClick={this.handleRegisterModal}>
											{translate.common_no}
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
				
				{/* Result THNX */}
				{this.state.showThnx && (
					<div className="admin-content">
						<h2>{translate.common_thnx}</h2>
						
						<div className="result result__thnx">
							
							<p>
								<img src={checkGreen} alt=""/>
								
								{this.state.action === 'register' && (
									translate.admin_content_ticket_is_registred
								)}
								{this.state.action === 'void' && (
									translate.admin_content_ticket_voided
								)}
							</p>
							
							<button
								onClick={() => this.props.showCodeValidation('codeValidation')}
								className="btn-transparent" type="button">
								{translate.common_go_back_to_home}
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ResultOkTicket;
