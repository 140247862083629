import React, {Component,} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from 'axios';

import ScrollToTop from './components/partials/ScrollToTop';
import Menu from './components/partials/Menu';
import Footer from './components/partials/Footer';
import Home from './components/home/Home';
import About from './components/about/About';
import Prices from './components/prices/Prices';
import Contacts from './components/contacts/Contacts';
import Textdoc from './components/textdoc/Textdoc';
import NotFound from './components/notfound/NotFound';
import Profile from './components/profile/Profile';

import BookVisit from './components/bookvisit/Book';
import ChooseSwimmingPool from './components/bookvisit/Choose-swimmping-pool';
import ChooseDay from './components/bookvisit/Choose-day';
import Rules from './components/bookvisit/Rules';
import SelectCount from './components/bookvisit/Select-count';
import ChooseCheckout from './components/bookvisit/Choose-checkout';
import Summary from './components/bookvisit/Summary';
import Confirmation from './components/bookvisit/Confirmation';

import Registration from './components/modals/Registration';
import RegistrationValidation from './components/modals/RegistrationValidation';
import Login from './components/modals/Login';
import ForgetPassword from './components/modals/Forgot'
import ResetPassword from './components/modals/Reset'
import PasswordConfirmation from './components/modals/Confirmation'

import SelectAdmin from './components/admin/Select-admin';
import CodeValidation from './components/admin/CodeValidation'

class App extends Component {
	constructor() {
		super();
		this.state = {
			menuopen: false,
			translate: [],
			sections: [],
			routes: [],
			language: 'lv',
			cookies: 1,
			disableHeader: false,
			disableFooter: false,
			transparent: false,
			base: '/',
		}
		
		this.toggleMenu = this.toggleMenu.bind(this);
		this.acceptCookie = this.acceptCookie.bind(this);
	}
	
	componentDidMount() {
		// Detect current language from location and get routes
		let ln = 'lv';
		
		if (window.location.pathname.includes('/en/')) {
			ln = 'en';
		}
		if (window.location.pathname.includes('/ru/')) {
			ln = 'ru';
		}
		
		// Site routes and sections
		axios.get(window.API + '/' + ln + '/api/routes')
			.then(result => {
				this.setState({
					routes: result.data.routes,
					sections: result.data.sections,
					cookies: result.data.cookies,
					language: ln,
					translate: {...this.state.translate, ...result.data.translate}
				});
			})
			.catch(error => console.log(error));
		
		// Reservation system translations
		axios.get(window.DEV_API + "api/translate")
			.then(result => {
				this.setState({
					translate: {...this.state.translate, ...result.data.translate}
				});
			})
			.catch(error => console.log(error));
	}
	
	toggleMenu = () => {
		const currentState = this.state.menuopen;
		this.setState({menuopen: !currentState});
		document.documentElement.className = currentState ? '' : 'no-scroll';
	}
	
	acceptCookie() {
		this.setState({cookies: 1});
		axios.post(window.API + '/' + this.state.language + '/api/cookie');
	}
	
	render() {
		return (
			<Router basename={this.state.base}>
				<ScrollToTop>
					<div className="wrap">
						
						<Menu
							sections={this.state.sections}
							toggleMenu={this.toggleMenu}
							menuopen={this.state.menuopen}
							language={this.state.language}
							translate={this.state.translate}
						/>
						
						<div className="app-content">
							{this.state.routes &&
							<Switch>
								<Route
									exact path="/"
									render={(props) => <Home language={this.state.language}
																					 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									exact path="/:lang"
									render={(props) => <Home language={this.state.language}
																					 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/profile"
									render={(props) => <Profile language={this.state.language}
																							translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/book-visit"
									render={(props) => <BookVisit language={this.state.language}
																								translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/choose-swimming-pool"
									render={(props) => <ChooseSwimmingPool language={this.state.language}
																												 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/choose-date"
									render={(props) => <ChooseDay language={this.state.language}
																								translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/rules"
									render={(props) => <Rules language={this.state.language}
																						translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/choose-count"
									render={(props) => <SelectCount language={this.state.language}
																									translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/choose-checkout"
									render={(props) => <ChooseCheckout language={this.state.language}
																										 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/summary"
									render={(props) => <Summary translate={this.state.translate}
																							language={this.state.language}{...props}/>}
								/>
								
								<Route
									path="/:lang/confirmation"
									render={(props) => <Confirmation translate={this.state.translate}
																									 language={this.state.language}{...props}/>}
								/>
								
								
								<Route
									path="/:lang/register"
									render={(props) => <Registration language={this.state.language}
																									 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/validation"
									render={(props) => <RegistrationValidation language={this.state.language}
																														 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/login"
									render={(props) => <Login language={this.state.language}
																						translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/forget-password"
									render={(props) => <ForgetPassword language={this.state.language}
																										 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/reset-password"
									render={(props) => <ResetPassword language={this.state.language}
																										translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/password-confirmation"
									render={(props) => <PasswordConfirmation language={this.state.language}
																													 translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/select-admin"
									render={(props) => <SelectAdmin language={this.state.language}
																									translate={this.state.translate} {...props}/>}
								/>
								
								<Route
									path="/:lang/code-validation"
									render={(props) => <CodeValidation language={this.state.language}
																										 translate={this.state.translate} {...props}/>}
								/>
								
								{this.state.routes.map(route => {
									if (route.component === 'About')
										return <Route exact path={route.path} key={route.id} render={(props) => <About
											translate={this.state.translate} {...props}/>}/>
									
									if (route.component === 'Textdoc')
										return <Route exact path={route.path} key={route.id} render={(props) => <Textdoc
											translate={this.state.translate} {...props}/>}/>
									
									if (route.component === 'Prices')
										return <Route exact path={route.path} key={route.id} render={(props) => <Prices
											translate={this.state.translate} {...props}/>}/>
									
									if (route.component === 'Contacts')
										return <Route exact path={route.path} key={route.id} render={(props) => <Contacts
											translate={this.state.translate} {...props}/>}/>
									
									if (route.component === '404')
										return <Route exact path={route.path} key={route.id} render={(props) => <NotFound
											translate={this.state.translate} {...props}/>}/>
									
									return <Route path="/" key="home" component={Home}/>
								})}
								
								{/* To 404 Page*/}
								<Route render={() => <NotFound translate={this.state.translate}/>}/>
							</Switch>
							}
						</div>
						
						<Footer
							sections={this.state.sections}
							translate={this.state.translate}
						/>
					</div>
				</ScrollToTop>
			</Router>
		);
	}
}

export default App;
