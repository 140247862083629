import React from 'react';
import {Helmet} from 'react-helmet';
import './Admin.css';

import Panel1 from '../../assets/img/panel-1.svg';
import Panel2 from '../../assets/img/panel-2.svg';
import Panel3 from '../../assets/img/panel-3.svg';

import {ReactComponent as ProfileImg} from "../../assets/img/profile.svg";
import axios from "axios";

class SelectAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			siteAdminLink: 'https://salaspilsbaseins.lv/site/admin/login',
			eCommercialAdminLink: 'https://tickets.salaspilsbaseins.lv/admin',
			ticketAdminLink: 'code-validation'
		}
		this.handleLogout = this.handleLogout.bind(this);
	}
	
	componentDidMount() {
		const user = JSON.parse(localStorage.getItem('user'));
		
		if (user !== null && user.group === '1') {
			this.setState({
				token: user.token,
				eCommercialAdminLink: this.state.eCommercialAdminLink + '?token='+user.token+''
				
			})
		} else {
			this.props.history.push('/')
		}

	}
	
	handleAdmin = (link) => {
		window.open(link, "_self");
	}
	
	handleLogout(e) {
		e.preventDefault();
		
		axios.post(window.DEV_API + 'api/user/logout', {}, {
			headers: {'Authorization': this.state.token}
		}).then(function (response) {
			window.open(window.location, "_self");
			localStorage.clear();
		}).catch(error => {
			console.log(error)
		})
	}
	
	render() {
		
		const translate = this.props.translate;
		
		return (
			<div>
				<Helmet>
					<title>{translate.admin_select_title}</title>
				</Helmet>
				
				<div className="admin-panels__menu">
					
					<a href={'/'}
						 onClick={this.handleLogout}
						 className="profile--link inner">
									<span>
										{translate.profile_body_logout}
									</span>
						<ProfileImg/>
					</a>
				
				</div>
				
				<div className="container admin-panels">
					
					<div className="row">
						
						<div className="col-xs-12">
							<h1>{translate.admin_select_title}</h1>
							<p className="subtitle">{translate.admin_select_subtitle}</p>
						</div>
					
					</div>
					
					<div className="row">
						<div
							className="col-sm-12 col-lg-4 panel"
							onClick={() => this.handleAdmin(this.state.siteAdminLink)}
						>
							<div className="link">
								<div className="img-wrap">
									<img className="img-responsive" src={Panel1} alt=""/>
								</div>
								<h6>{translate.admin_select_panel_1_title}</h6>
								
								<p>{translate.common_go}</p>
							</div>
						</div>
						
						<div
							className="col-sm-12 col-lg-4 panel"
							onClick={() => this.handleAdmin(this.state.eCommercialAdminLink)}
						>
							<div className="link">
								<div className="img-wrap">
									<img className="img-responsive" src={Panel2} alt=""/>
								</div>
								<h6>{translate.admin_select_panel_2_title}</h6>
								<p>{translate.common_go}</p>
							</div>
						</div>
						
						<div
							className="col-sm-12 col-lg-4 panel"
							onClick={() => this.handleAdmin(this.state.ticketAdminLink)}
						>
							<div className="link">
								<div className="img-wrap">
									<img className="img-responsive" src={Panel3} alt=""/>
								</div>
								<h6>{translate.admin_select_panel_3_title}</h6>
								<p>{translate.common_go}</p>
							</div>
						</div>
					
					</div>
				
				</div>
			</div>
		);
	}
}

export default SelectAdmin;
