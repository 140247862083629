import { Component } from 'react';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';

class ScrollToTop extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-132420499-1');
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactGA.pageview(this.props.location.pathname);
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
