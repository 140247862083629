import React from 'react';
import axios from 'axios';
import {NavLink} from "react-router-dom";
import {Helmet} from 'react-helmet';
import Parallax from 'parallax-js';

import './Forms.css';
import bgImg from "../../assets/img/book-visit.svg";

import {ReactComponent as Close} from '../../assets/img/close.svg';
import {ReactComponent as Email} from '../../assets/img/email.svg';

class ForgotModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: false,
			emailSuccess: false,
			isActive: true
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.goBack = this.goBack.bind(this);
		
	}
	
	componentDidMount() {
		this.parallax = new Parallax(this.scene)
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	handleSubmit = e => {
		e.preventDefault();
		
		this.setState({
			isActive: false
		})
		
		let self = this;

		axios.post(window.DEV_API + 'api/user/forgot', {
				email: this.state.email,
			})

			.then(function (response) {
				
				if(response.data.errors){
					if (response.data.errors.user ) {
						self.setState({
							emailError: true,
							isActive: true
						})
					} else {
						self.setState({
							emailError: false,
						})
					}
				} else{
						self.setState({
							emailSuccess:true,
							emailError: false,
					})
				}
			})

			.catch(error => {
				console.log(error)
			})
		
	};
	
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
	};
	
	goBack(){
		this.props.history.push('/')
	}
	
	render() {
		
		const translate = this.props.translate;
		const formErrors = this.state;
		
		return (
			<div>
				
				<Helmet>
					<title>{translate.forget_password_title}</title>
				</Helmet>
				
				<div className="container-fluid form-wrap">
					
					<h1> {translate.form_forget_title	} </h1>
					
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					<p className="history-back"
						 onClick={this.goBack}>
						{translate.common_close}
						<Close/>
					</p>
					
					<div className="form-wrap__content">
						
						<form onSubmit={this.handleSubmit} noValidate>
							
							<div className="inputs">
								
								<div className={formErrors.emailError || formErrors.globalError ? "form-group error" : "form-group"}>
									<input className="form-control email"
												 type="text"
												 id="email"
												 value={this.state.email}
												 onChange={this.handleChange('email')}
												 required/>
									
									<label className="form-control-placeholder"
												 htmlFor="email">{translate.form_email}</label>
									
									<div className="icon">
										<Email/>
									</div>
									
									{formErrors.emailError && (
										<span className="error-msg">{translate.form_error_email}</span>
									)}
									
									{formErrors.emailSuccess && (
										<span className="success-msg">{translate.form_forget_success_msg}</span>
									)}
								</div>
								
								
								<NavLink to={'/' + this.props.language + 'forget-password'} className="forgot-pass">{translate.form_login_Login_btn}</NavLink>
							</div>
							
							
							<button
								disabled={!this.state.isActive}
								className="form-button">
								{translate.form_reset_btn}
							</button>
						</form>
						
					</div>
				
				
				</div>
			
			</div>
		);
	}
}

export default ForgotModal;
