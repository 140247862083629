import React from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';
import './Profile.css';

import ReservedVisits from "./partials/Reserved-visits"
import PersonalData from "./partials/Persona-data";

import {ReactComponent as ProfileImg} from '../../assets/img/profile.svg';
import {ReactComponent as MenuArrow} from '../../assets/img/arrow-down.svg';

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			dropdownVisible: false,
			showReservation: true,
			showPersonalData: false,
			qr: '',
			qrId: '',
			userData: [],
			user: JSON.parse(localStorage.getItem('user')) || false
		}

		this.handleDropdown = this.handleDropdown.bind(this);
		this.showReservation = this.showReservation.bind(this);
		this.showPersonalData = this.showPersonalData.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {

		localStorage.removeItem('timer');
		localStorage.removeItem('price');
		localStorage.removeItem('priceId');
		localStorage.removeItem('discount-price');
		localStorage.removeItem('ticket-title');
		localStorage.removeItem('ticket-type');
		localStorage.removeItem('pool-title');
		localStorage.removeItem('product-id');
		localStorage.removeItem('selected-count');
		localStorage.removeItem('selected-count-title');
		localStorage.removeItem('selected-date');
		localStorage.removeItem('selected-time');
		localStorage.removeItem('selected-email');
		localStorage.removeItem('sessionID');
		localStorage.removeItem('reserved-tickets');
		localStorage.removeItem('files');


		if (!this.state.user) {
			this.props.history.push('/' + this.props.match.params.lang)
		} else {
			if (this.state.user.group === '1') {
				this.props.history.push('/' + this.props.match.params.lang)

			} else {

				this.findReservations(this.state.user);

				this.setState({
					token: this.state.user.token,
					qr: this.state.user.qrcode_png,
					qrId: this.state.user.qrcode_id
				});
			}
		}
		// window.addEventListener('popstate', this.handleLink);
	}

	handleDropdown(evt) {
		evt.preventDefault();

		this.setState({dropdownVisible: !this.state.dropdownVisible})
	}

	showReservation() {
		this.setState({
			showReservation: true,
			showPersonalData: false,
			dropdownVisible: false,
		})
	}

	showPersonalData() {
		this.setState({
			showReservation: false,
			showPersonalData: true,
			dropdownVisible: false,
		})
	}

	handleLink() {
		if (this.state.showReservation) {
			this.props.history.push('/')
		} else {
			this.props.history.push('/' + this.props.match.params.lang + '/profile')
			this.showReservation();
		}

		// window.removeEventListener('popstate', this.handleLink);
	}

	findReservations() {

		if (this.state.user !== null) {

			let self = this;

			axios.post(window.DEV_API + 'api/user/profile', {}, {
				headers: {'Authorization': this.state.user.token}
			}).then(function (response) {
				self.setState({
					user: response.data.user,
				});

			}).catch(error => {
				console.log(error)
			})
		}
	};

	handleSubscriptionReservation() {
		localStorage.setItem('ticket-title', this.props.translate.profile_body_available_subscriptions);
		localStorage.setItem('ticket-type', 'subscription')
		this.props.history.push('choose-swimming-pool')
	}

	handleLogout() {
		axios.post(window.DEV_API + 'api/user/logout', {}, {
			headers: {'Authorization': this.state.token}
		}).then(function () {
			window.open(window.location, "_self");
			localStorage.clear();
		}).catch(error => {
			console.log(error)
		})
	}

	render() {
		const translate = this.props.translate;

		return (
			<div>
				<Helmet>
					<title>{translate.side_profile_title}</title>
				</Helmet>

				<div className="container-fluid">


					<div className="profile__header mobile">

						<p className="user-name">{translate.common_hi} {this.state.user.name}</p>

						<a href={'/'}
							 onClick={this.handleDropdown}
							 className="profile--link inner">
									<span>
										{translate.menu_profile_btn}
										<MenuArrow/>
									</span>
							<ProfileImg/>
						</a>

						{/* Profile navigation */}
						<div className={'profile__dropdown ' + (this.state.dropdownVisible ? 'show' : '')}>
							<ul>
								<li className={this.state.showReservation ? 'active' : ''}
										onClick={this.showReservation}>
									{translate.side_profile_my_data}
								</li>
								<li className={this.state.showPersonalData ? 'active' : ''}
										onClick={this.showPersonalData}>
									{translate.side_profile_my_profile}
								</li>
								<li onClick={this.handleLogout}>
									{translate.profile_body_logout}
								</li>
							</ul>
						</div>

					</div>

					<div className="row">
						<div className="sidebar sidebar__profile col-lg-4 col-md-12">

							<div className="sidebar-arrow">
								<p className="back-arrow" onClick={this.handleLink}></p>
							</div>

							<div className="top">
								<h1>{translate.side_profile_title}</h1>
								<p>{translate.side_profile_text}</p>
								<div className="inner-link">
									{this.state.showReservation && (
										<p onClick={this.showPersonalData}>
											<ProfileImg/>{translate.side_profile_my_profile}
										</p>
									)}

									{this.state.showPersonalData && (
										<p onClick={this.showReservation}>
											<ProfileImg/>{translate.side_profile_my_data}
										</p>
									)}
								</div>

								{this.state.qr && (
									<div className="profile__info">

										<div className="profile__info-top">
											<h2>{translate.profile_body_available_subscriptions}</h2>

											<div className="qr-code">
												<img src={this.state.qr} alt="qr"/>
												<p>{translate.common_nr} {this.state.qrId}</p>
											</div>

											<div className="pool">
												{this.state.user.subscriptions.map((subscription, index) =>
													<div className="pool__visits" key={index}>
														<span>{subscription.product_title}:</span>
														<span>{subscription.available} {subscription.available !== 1
															? translate.profile_body_visits
															: translate.profile_body_visit}</span>
													</div>
												)}
											</div>
										</div>

										<div className="profile__info-bottom">
											<div className="btn-wrap">
												<button className="btn btn-red"
																onClick={e => this.handleSubscriptionReservation()}>
													{translate.profile_body_fill_subscription}
												</button>
											</div>
										</div>
									</div>
								)}

							</div>

							<div className="bottom">
								<span dangerouslySetInnerHTML={{__html: translate.sidebar_link}}></span>
							</div>
						</div>

						<div className="profile__content col-lg-8 col-md-12">
							<div className="user-info">
								<p className="user-name">{translate.common_hi} {this.state.user.name}</p>

								<div className="profile__header desktop">
									<a href={'/'}
										 onClick={this.handleDropdown}
										 className="profile--link inner">
									<span>
										{translate.menu_profile_btn}
										<MenuArrow/>
									</span>
										<ProfileImg/>
									</a>

									{/* Profile navigation */}
									<div className={'profile__dropdown ' + (this.state.dropdownVisible ? 'show' : '')}>
										<ul>
											<li className={this.state.showReservation ? 'active' : ''}
													onClick={this.showReservation}>
												{translate.side_profile_my_data}
											</li>
											<li className={this.state.showPersonalData ? 'active' : ''}
													onClick={this.showPersonalData}>
												{translate.side_profile_my_profile}
											</li>
											<li onClick={this.handleLogout}>
												{translate.profile_body_logout}
											</li>
										</ul>
									</div>

								</div>
							</div>


							{this.state.showReservation && (
								<div>
									<ReservedVisits language={this.props.language}
																	updateVisits={this.findReservations.bind(this)}
																	translate={this.props.translate} {...this.props}/>
								</div>
							)}

							{this.state.showPersonalData && (
								<PersonalData translate={this.props.translate}/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Profile;
