import React from 'react';
import { Helmet } from 'react-helmet';
// Style
import './NotFound.css';

// Assets
import notFound from '../../assets/img/time.svg'

class NotFound extends React.Component {

  render () {
    return (
      <div>
        <Helmet>
          <title>Error 404</title>
        </Helmet>

        <div className="container">

          <div className="not-found">

            <div className="row">
              <div className="col">
                <img src={notFound} alt="404" />
                <h6>{this.props.translate.page_not_found}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
