import React from 'react';
import axios from 'axios';
import {NavLink} from "react-router-dom";
import {Helmet} from 'react-helmet';
import Parallax from 'parallax-js';

import './Forms.css';
import bgImg from "../../assets/img/book-visit.svg";

import {ReactComponent as Close} from '../../assets/img/close.svg';
import {ReactComponent as Email} from '../../assets/img/email.svg';
import {ReactComponent as Password} from '../../assets/img/password.svg';


class LoginModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hidden: true,
			email: '',
			password: '',
			globalError: false,
			emailError: false,
		};
		
		this.toggleShow = this.toggleShow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.goBack = this.goBack.bind(this);
		
	}
	
	componentDidMount() {
		this.parallax = new Parallax(this.scene)
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	handleSubmit = e => {
		e.preventDefault();
		
		let self = this;
		
		axios.post(window.DEV_API + 'api/user/login', {
				email: this.state.email,
				password: this.state.password
			})
			
			.then(function (response) {
				
				if(response.data.errors){
					if (response.data.errors.email ) {
						self.setState({
							emailError: true,
						})
					} else {
						self.setState({
							emailError: false,
						})
					}
					
					if (response.data.errors.error) {
						self.setState({
							globalError: true,
						})
					} else {
						self.setState({
							globalError: false,
						})
					}
				} else{
					localStorage.setItem('user', JSON.stringify(response.data.user));
					
					// Logged in User or Admin
					if(response.data.user.group === '1'){
						localStorage.setItem('loggedIn', 'admin');
						self.props.history.push('select-admin')
					} else {
						localStorage.setItem('loggedIn', 'user');
						self.props.history.push('profile')
					}
					
				}
			}).catch(error => {
				 console.log(error)
			})
		
	};
	
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
	};
	
	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}
	
	goBack(){
		this.props.history.push('/')
	}
	
	render() {
		
		const translate = this.props.translate;
		const formErrors = this.state;
		
		return (
			<div>
				<Helmet>
					<title>{translate.form_login_title}</title>
				</Helmet>
				
				<div className="container-fluid form-wrap">
					
					<h1> {translate.form_login_title} </h1>
					
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					<p className="history-back"
						 onClick={this.goBack}>
						{translate.common_close}
						<Close/>
					</p>
					
					<div className="form-wrap__content">
						
						<form onSubmit={this.handleSubmit} noValidate>
							
							<div className="inputs">
								
								<div className={formErrors.emailError || formErrors.globalError ? "form-group error" : "form-group"}>
									<input className="form-control email"
												 type="text"
												 id="email"
												 value={this.state.email}
												 onChange={this.handleChange('email')}
												 required/>
									
									<label className="form-control-placeholder"
												 htmlFor="email">{translate.form_email}</label>
									
									<div className="icon">
										<Email/>
									</div>
									
									{formErrors.emailError && (
										<span className="error-msg">{translate.form_error_email}</span>
									)}
								</div>
								
								
								<div className={formErrors.globalError ? "form-group error" : "form-group"}>
									<input className="form-control password"
												 type={this.state.hidden ? "password" : "text"}
												 id="password"
												 value={this.state.password}
												 onChange={this.handleChange('password')}
												 required/>
									
									<label className="form-control-placeholder"
												 htmlFor="password">{translate.form_password}
									</label>
									
									<div className="icon">
										<Password onClick={this.toggleShow}/>
									</div>
									
									{formErrors.globalError && (
										<span className="error-msg">{translate.form_login_email_not_found}</span>
									)}
								</div>
								
								
								<NavLink to={'/' + this.props.language + '/forget-password'} className="forgot-pass">{translate.form_login_forget_password}</NavLink>
							</div>
							
							
							<button
								className="form-button">
								{translate.form_login_login_btn}
							</button>
						</form>
						
						<NavLink className='login'
										 to={"register"}>
							{translate.form_login_registration_btn}
						</NavLink>
					</div>
				
				
				</div>
			
			</div>
		);
	}
}

export default LoginModal;
