import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Logo from'../../assets/img/logo.png';

import './Footer.css';

class Footer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      cssClass: 'hidden',
      cssHidden: ''
    };
  }
  
  componentWillMount() {
    // Listen for URL change and set menu CSS class
    this.setFooterClass(this.props.history.location.pathname);
    
    this.props.history.listen(() => {
      this.setFooterClass(this.props.history.location.pathname);
    });
    
    
  }
  
  setFooterClass = (pathname) => {
    let pathLength = 1;
    let helper = pathname.split('-').shift();
    let bookingName = helper.split('/').pop();
    
    if(pathname !== '/'){
      pathLength = pathname.split(/[?#]/).shift().match(/\/[^/]+?/g).length;
    }
    this.setState({cssClass:pathLength > 1 ? ' menu--inside' : ''})
    this.setState({cssHidden:bookingName === 'izveleties' || 'rezervet' ? ' hidden' : ''})
  }
  
  render () {
    return (
      <div className={'footer ' + this.state.cssClass + this.state.cssHidden}>
        <div className="container">
          <div className="row">
            
            {this.props.sections.map(section =>
              <div className="col col-12 col-sm-12 col-md-3" key={section.id}>
                <h6><NavLink to={section.link}>{section.title}</NavLink></h6>
                
                {section.subs.length > 0 && (
                  <ul>
                    {section.subs.map(sub =>
                      <li key={sub.id}><NavLink to={sub.link}>{sub.title}</NavLink></li>
                    )}
                  </ul>
                )}
              </div>
            )}
            
            <div className="col col-sm-12 col-md-3 d-none d-md-block">
              <div className="footer__logo">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col col-5 col-md-12 col-lg-12">
              <p>{this.props.translate.copyright}</p>
            </div>
            <div className="col col-7 d-md-none">
              <div className="footer__logo">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
