import React from 'react';
import {Helmet} from "react-helmet";
import Calendar from "../calendar/Calendar";
import moment from "moment";
import 'moment/locale/lv'
import 'moment/locale/en-gb'
import 'moment/locale/ru'
import './Book.css';

import Sidebar from "./Sidebar";

class ChooseDay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newNavLink: false,
			hideCalendar: true,
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedPoolType: localStorage.getItem('pool-title'),
			selectedCount: localStorage.getItem('selected-count'),
			selectedCountTitle: localStorage.getItem('selected-count-title'),
			selectedDate: localStorage.getItem('selected-date')
		}

		this.handleLink = this.handleLink.bind(this);
	}

	componentDidMount() {
		if (!this.state.selectedBookTypeTitle
			|| !this.state.selectedPoolType
			|| !this.state.selectedCount) {
			this.props.history.push('/' + this.props.match.params.lang + '/book-visit');
		}

		this.setState({
			selectedDate: moment().locale(this.props.match.params.lang).format('dddd DD. MMMM')
		})
		// window.addEventListener('popstate', this.handleLink);
	}

	onChangeDate(newDate) {
		localStorage.setItem('selected-date', newDate);

		this.setState({
			selectedDate: newDate
		})
	}

	onChangeLink(newLink) {
		if (newLink === true) {
			this.setState({
				newNavLink: true,
				hideCalendar: false
			})
		}
	}

	handleLink(){
		this.props.history.push('choose-count')
		// window.removeEventListener('popstate', this.handleLink);
	}

	render() {
		const translate = this.props.translate;

		const choise = [
			{name: this.state.selectedBookTypeTitle},
			{name: this.state.selectedPoolType},
			{name: this.state.selectedCountTitle},
			{name: this.state.selectedDate}
		]

		return (
			<div>
				<Helmet>
					<title>{translate.choose_day_title}</title>
				</Helmet>

				<div className="container-fluid">
					<div className="row">

						<div className="sidebar-wrap col-lg-5 col-md-12">
							<div className="sidebar-arrow">
								{
									!this.state.newNavLink
										? <p className="back-arrow" onClick={this.handleLink}></p>
										: <p className="back-arrow"
												 onClick={() => {this.setState({hideCalendar: true, newNavLink: false})}}></p>
								}
							</div>

							<Sidebar
								bgHeight={50}
								parentProps={this.props}
								translate={this.props.translate}
								items={choise}/>
						</div>

						<div className="content col-lg-7 col-md-12">
							<div className="row active-step">
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3"></div>
								<div className="col-sm-3"></div>
							</div>

							<div className="header">
								<div className="row">
									<h2>{translate.choose_day_title}</h2>
									<p>{translate.choose_day_intro}</p>
								</div>
							</div>

							<div className="choose-day row">
								<Calendar
									lang={this.props.match.params.lang}
									translate={translate}
									parentProps={this.props}
									changeDate={this.onChangeDate.bind(this)}
									changeLink={this.onChangeLink.bind(this)}
									hideCalendar={this.state.hideCalendar}
									navLink={this.state.newNavLink}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChooseDay;
