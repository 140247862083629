import React from 'react';
import checkGreen from "../../../assets/img/check-green.svg";
import axios from "axios";

class ResultWrongTime extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showThnx: false,
			showResult: true,
		}

		this.handleVoid = this.handleVoid.bind(this);
	}

	handleVoid() {
		const user = JSON.parse(localStorage.getItem('user'));
		let self = this

		axios.post(window.DEV_API + 'api/admin/cancel', {
			reservation_id: this.props.ticketData.id,
			product_id: this.props.ticketData.product_id,
		}, {
			headers: {'Authorization': user.token}
		}).then(function (response) {
			self.setState({
				showResult: false,
				showThnx: true,
			})
		}).catch(error => {
			console.log(error)
		})
	}

	render() {
		const translate = this.props.translate;
		const ticket = this.props.ticketData

		return (

			<div>
				{/* Result Empty */}
				{this.state.showResult && (
					<div className="admin-content">

						<h2>{translate.admin_content_result}</h2>
						<p>{translate.admin_content_wrong_time}</p>

						<div className="result result__wrong-time">

							<div className="header">

								<p>{translate.admin_content_not_valid}</p>

							</div>

              {ticket.type !== "group" && (
  							<div className="item featured">
  								<div><p>{ticket.price_title}</p></div>
  								<div><p></p></div>
  							</div>
              )}

							<div className="item">
								<div><p>{ticket.product_title}</p></div>
								<div></div>
							</div>

							<div className="item">
								<div><p className="selected-time">{ticket.date}</p></div>
							</div>

              {ticket.type !== "group" && (
                <div>
    							<div className="item">
    								<div>
    									{/* if discount than show span text */}
    									<span>( {translate.admin_content_discount_text} )</span>
    									<p>{translate.admin_content_discount}:</p>
    								</div>
    								<div><p>{ticket.discount > '0' ? translate.common_yes : translate.common_no}</p></div>
    							</div>

    							<div className="item">
    								<div><p>{translate.admin_content_price}:</p></div>
    								<div><p className="price">{ticket.total} {translate.common_eur}</p></div>
    							</div>
                </div>
              )}

						</div>

						<div className="result__empty btns with_space">
							<button
								onClick={() => this.props.showCodeValidation('codeValidation')}
								className="btn-transparent" type="button">
								{translate.common_go_back_to_home}
							</button>

              {ticket.type !== "group" && (
							<button onClick={this.handleVoid}
											className="btn-red btn">
								{translate.common_void}
							</button>
              )}
						</div>

					</div>
				)}

				{/* Result THNX */}
				{this.state.showThnx && (
					<div className="admin-content">
						<h2>{translate.common_thnx}</h2>

						<div className="result result__thnx">

							<p>
								<img src={checkGreen} alt=""/>
								{translate.admin_content_ticket_voided}
							</p>

							<button
								onClick={() => this.props.showCodeValidation('codeValidation')}
								className="btn-transparent" type="button">
								{translate.common_go_back_to_home}
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ResultWrongTime;
