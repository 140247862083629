import React from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import Parallax from 'parallax-js';

import './Forms.css';
import bgImg from "../../assets/img/book-visit.svg";

import {ReactComponent as Close} from '../../assets/img/close.svg';
import {ReactComponent as Password} from "../../assets/img/password.svg";

class ResetModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hidden: true,
			hiddenAgain: true,
			showForm: true,
			showSuccess: false,
			
			code: '',
			password: '',
			passwordAgain: '',
			
			passwordError: false,
			passwordAgainError: false,
			
			formValid: false,
			passwordValid: false,
			passwordAgainValid: false,
			
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.toggleAgainShow = this.toggleAgainShow.bind(this);
		this.goBack = this.goBack.bind(this);
	}
	
	componentDidMount() {
		this.parallax = new Parallax(this.scene);
		
		const query = new URLSearchParams(this.props.location.search);
		
		this.setState({
			code: query.get('code')
		})
	}
	
	componentWillUnmount() {
		this.parallax.disable()
	}
	
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
		
		this.validateField(name, event.target.value)
	};
	
	validateField(fieldName, value) {
		
		let passwordValid = this.state.passwordValid;
		let passwordAgainValid = this.state.passwordAgainValid;
		
		switch (fieldName) {
			case "password":
				passwordValid = !!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);
				break;
			case "passwordAgain":
				passwordAgainValid = !!value.match(this.state.password)
				break;
			default:
				break;
		}
		
		this.setState({
			passwordValid: passwordValid,
			passwordAgainValid: passwordAgainValid,
		})
	}
	
	handleSubmit = e => {
		e.preventDefault();
		
		if(!this.state.passwordValid){
			this.setState({
				passwordError: true
			})
		}
		
		else if(!this.state.passwordAgainValid){
			this.setState({
				passwordAgainError: true
			})
		} else {
			let self = this;
			
			axios.post(window.DEV_API + 'api/user/reset', {
				code: this.state.code,
				password: this.state.passwordAgain
			}).then(function (response) {
				
				if (!response.data.errors) {
					self.setState({
						passwordError: false,
						passwordAgainError: false,
					})
					
					self.props.history.push('password-confirmation')
				} else {
					
					if (response.data.errors.password) {
						self.setState({
							passwordError: true
						})
					}
					
					if (response.data.errors.code) {
						alert("Šāds kods neeksistē")
					}
				}
				
				
			}).catch(error => {
				console.log(error)
			})
		}
	};
	
	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}
	
	toggleAgainShow() {
		this.setState({hiddenAgain: !this.state.hiddenAgain});
	}
	
	goBack() {
		this.props.history.push('/')
	}
	
	render() {
		
		const translate = this.props.translate;
		const formErrors = this.state;
		
		
		return (
			<div>
				<Helmet>
					<title>{translate.reset_password_title}</title>
				</Helmet>
				
				
				<div className="container-fluid form-wrap reset">
					
					<div className="absolute-bg" ref={el => this.scene = el}>
						<img data-depth="0.5" src={bgImg} alt="background"/>
					</div>
					
					<p className="history-back"
						 onClick={this.goBack}>
						{translate.common_close}
						<Close/>
					</p>
					
					{this.state.showForm && (
						<div>
							<h1> {translate.form_reset_title} </h1>
							
							<div className="form-wrap__content">
								
								<form onSubmit={this.handleSubmit} noValidate>
									
									<div className="inputs">
										
										<div className={formErrors.passwordError ? "form-group error" : "form-group"}>
											<input className="form-control password"
														 type={this.state.hidden ? "password" : "text"}
														 id="password"
														 value={this.state.password}
														 onChange={this.handleChange('password')}
														 required/>
											
											<label className="form-control-placeholder"
														 htmlFor="password">{translate.form_password}
											</label>
											
											<div className="icon">
												<Password onClick={this.toggleShow}/>
											</div>
											
											{formErrors.passwordError && (
												<span className="error-msg">{translate.form_error_password}</span>
											)}
										</div>
										
										<div
											className={formErrors.passwordAgainError ? "form-group error" : "form-group"}>
											<input className="form-control password"
														 type={this.state.hiddenAgain ? "password" : "text"}
														 id="passwordAgain"
														 value={this.state.passwordAgain}
														 onChange={this.handleChange('passwordAgain')}
														 required/>
											<label className="form-control-placeholder"
														 htmlFor="passwordAgain">{translate.form_password_again}
											</label>
											
											<div className="icon">
												<Password onClick={this.toggleAgainShow}/>
											</div>
											
											{formErrors.passwordAgainError && (
												<span className="error-msg">{translate.form_error_password_again}</span>
											)}
										</div>
									</div>
									
									<button
										className="form-button">
										{translate.form_reset_btn}
									</button>
								</form>
							
							</div>
						</div>
					)}
					
					{this.state.showSuccess && (
						<div className="success">
							
							<h1>{translate.form_login_password_changed_success_title	}</h1>
							<a href={'login'} className="btn btn-red">{translate.form_login_login_btn}</a>
						
						</div>
					)}
				
				</div>
			
			</div>
		);
	}
}

export default ResetModal;
