import React from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

import './Book.css';
import {ReactComponent as Password} from "../../assets/img/password.svg";
import {ReactComponent as Email} from "../../assets/img/email.svg";
import Sidebar from './Sidebar'

class ChooseCheckout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBookTypeTitle: localStorage.getItem('ticket-title'),
			selectedBookType: localStorage.getItem('ticket-type'),
			selectedPoolType: localStorage.getItem('pool-title'),
			selectedDate: localStorage.getItem('selected-date'),
			selectedCount: localStorage.getItem('selected-count-title'),
			selectedTime: localStorage.getItem('selected-time'),
			user: JSON.parse(localStorage.getItem('user')) || false,
			email:  localStorage.getItem('selected-email') || '',
			code: '',
			emailValid: false,
			codeValid: false,
			noErrors: false,
			emailErrorWithoutReg: false,
			codeErrorWithoutReg: false,
			hidden: true,
			emailLogin: '',
			password: '',
			loggedInUser: '',
			showPersonalUseInput: false,
			globalError: false,
			emailError: false,
		}

		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleLoginChange = this.handleLoginChange.bind(this);
		this.handleLink = this.handleLink.bind(this);
		this.continueWithoutProfile = this.continueWithoutProfile.bind(this)
	}

	componentDidMount() {
		// Place user email in input field
		if (this.state.user) {

			// If logged in registered user
			if (this.state.user.group === '0') {
				this.setState({
					loggedInUser: true,
					email: this.state.user.email,
				})

				this.props.history.push('summary');
			}

			// If logged in admin
			if (this.state.user.group === '1') {
				this.setState({
					loggedInUser: true,
					showPersonalUseInput: true,
					email: ''
				})
			}
		}


			this._getStorageData();
		// window.addEventListener('popstate', this.handleLink);
	}

	_getStorageData = async () => {
		try {
			const value = await localStorage.getItem('selected-email');

			if (value !== null) {
				this.setState({
					noErrors: true,
				})
			}
		} catch (error) {
			console.log(error)
		}
	};


	continueWithoutProfile(){
		this.setState({
			noErrors: true
		})
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

	validateField(fieldName, value) {
		let emailValid = this.state.emailValid;
		let codeValid = this.state.codeValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			case 'code':
				codeValid = (value.length >= 12 && value.length < 13) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
			codeValid: codeValid
		}, this.validateForm);

	}

	validateForm() {
		this.setState({
			noErrors: this.state.emailValid || this.state.codeValid
		});
	}

	handleSubmit = evt => {
		evt.preventDefault();

		this.validateForm()

		if (!this.state.noErrors) {

			this.setState({
				emailErrorWithoutReg: !this.state.emailValid,
				codeErrorWithoutReg: !this.state.codeValid
			});

		} else {

			this.props.history.push('summary');

			localStorage.setItem('selected-email', this.state.email)
			localStorage.setItem('personal-code', this.state.code)

			this.setState({
				emailErrorWithoutReg: false,
				codeErrorWithoutReg: false
			})
		}
	};

	handleLoginSubmit = evt => {
		evt.preventDefault();

		let self = this;

		axios.post(window.DEV_API + 'api/user/login', {
			email: this.state.emailLogin,
			password: this.state.password
		}).then(function (response) {

			if (response.data.errors) {
				if (response.data.errors.email) {
					self.setState({
						emailError: true,
					})
				} else {
					self.setState({
						emailError: false,
					})
				}

				if (response.data.errors.error) {
					self.setState({
						globalError: true,
					})
				} else {
					self.setState({
						globalError: false,
					})
				}
			} else {
				localStorage.setItem('user', JSON.stringify(response.data.user))
				self.props.history.push('summary')
			}
		}).catch(error => {
			console.log(error)
		})

	};

	handleLoginChange = name => event => {
		this.setState({
			[name]: event.target.value
		})
	};

	toggleShow() {
		this.setState({hidden: !this.state.hidden});
	}

	handleRegistration() {
		this.props.history.push('register')
	}

	handleLink() {
		if (this.state.selectedBookType === 'subscription' || this.state.selectedBookType === 'spa') {
			this.props.history.push('choose-count')
		} else {
			this.props.history.push('choose-date')
		}

		// window.removeEventListener('popstate', this.handleLink);
	}

	render() {

		let translate = this.props.translate;
		let formErrors = this.state;

		let choise = [
			{name: this.state.selectedBookTypeTitle},
			{name: this.state.selectedPoolType},
			{name: this.state.selectedCount},
		]

		if (this.state.selectedDate) {
			choise.push(
				{name: this.state.selectedDate + ',' + this.state.selectedTime}
			);
		}

		return (
			<div>

				<Helmet>
					<title>{translate.form_login_title}</title>
				</Helmet>

				<div className="container-fluid">

					<div className="row">

						<div className="sidebar-wrap col-lg-5 col-md-12">

							<div className="sidebar-arrow">
								<p className="back-arrow"
									 onClick={e => this.handleLink()}>
								</p>
							</div>

							<Sidebar
								bgHeight={80}
								parentProps={this.props}
								translate={this.props.translate}
								items={choise}/>
						</div>

						<div className="content col-lg-7 col-md-12">
							<div className="row active-step">
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
								<div className="col-sm-3 active"></div>
							</div>

							<div className="choose-checkout row">
								<div
									className={'without-registration ' + (this.state.loggedInUser ? 'col-sm-12 logged-in' : 'col-sm-6')}>
									<h3>{translate.choose_swimming_pool_without_registration}</h3>
									<p>{translate.choose_swimming_pool_where_to_get_ticket}</p>

									<form>
										<div className={'form-group' + (this.state.emailErrorWithoutReg ? ' error' : '')}>

											<input type="text"
														 name="email"
														 className="form-control email"
														 value={this.state.email}
														 onChange={this.handleChange('email')}/>
											<label className="form-control-placeholder"
														 htmlFor="email">{translate.form_email}
											</label>

											<div className="icon">
												<Email/>
											</div>

											<span
												className={'error-msg' + (this.state.emailErrorWithoutReg ? ' visible' : ' hidden')}>{translate.form_error_email}</span>
										</div>

										{this.state.showPersonalUseInput && (
											<div>
												<p> {translate.confirmation_or} </p>

												<div className={'form-group' + (this.state.codeErrorWithoutReg ? ' error' : '')}>

													<input type="text"
																 name="code"
																 className="form-control code"
																 value={this.state.code}
																 onChange={this.handleChange('code')}/>
													<label className="form-control-placeholder"
																 htmlFor="code">{translate.form_personal_code}
													</label>


													<span
														className={'error-msg' + (this.state.codeErrorWithoutReg ? ' visible' : ' hidden')}>{translate.form_error_code}</span>
												</div>

												<div className='accept-rules'>
													<label><input type="checkbox" onChange={this.continueWithoutProfile} />
														<div></div>
														<p>{translate.form_continue_without_profile	}</p>
													</label>
												</div>
											</div>
										)}


										<button onClick={this.handleSubmit}
														className='btn-transparent'>
											{translate.common_continue}
										</button>

									</form>
								</div>

								{!this.state.loggedInUser && (
									<div className="col-sm-6 with-profile">

										<h3>{translate.form_login_title}</h3>

										<form onSubmit={this.handleLoginSubmit} noValidate>

											<div className="inputs">

												<div
													className={formErrors.emailError || formErrors.globalError ? "form-group error" : "form-group"}>
													<input className="form-control email"
																 type="text"
																 id="email-login"
																 value={this.state.emailLogin}
																 onChange={this.handleLoginChange('emailLogin')}
																 required/>

													<label className="form-control-placeholder"
																 htmlFor="email-login">{translate.form_email}</label>

													<div className="icon">
														<Email/>
													</div>

													{formErrors.emailError && (
														<span className="error-msg">{translate.form_error_email}</span>
													)}
												</div>


												<div className={formErrors.globalError ? "form-group error" : "form-group"}>
													<input className="form-control password"
																 type={this.state.hidden ? "password" : "text"}
																 id="password"
																 value={this.state.password}
																 onChange={this.handleLoginChange('password')}
																 required/>

													<label className="form-control-placeholder"
																 htmlFor="password">{translate.form_password}
													</label>

													<div className="icon">
														<Password onClick={this.toggleShow}/>
													</div>

													{formErrors.globalError && (
														<span className="error-msg">{translate.form_login_email_not_found}</span>
													)}
												</div>


												<NavLink to={'/' + this.props.language + '/forget-password'}
																 className="forgot-pass">{translate.form_login_forget_password}</NavLink>
											</div>

											<div className="btn-wrap">
												<button
													className="btn-red btn">
													{translate.form_login_login_btn}
												</button>

												<button
													onClick={this.handleRegistration.bind(this)}
													className="btn-transparent">
													{translate.form_btn_register}
												</button>
											</div>

										</form>


									</div>
								)}

							</div>

						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default ChooseCheckout;
